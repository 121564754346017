import React, { useEffect, useState } from "react";
import { AutocompleteArrayInput, AutocompleteInput, BooleanInput, DateInput, DateTimeInput, FormDataConsumer, FormWithRedirect, GET_LIST, NumberInput, ReferenceArrayInput, ReferenceInput, SelectInput, SimpleForm, TextInput, useFormContext, useNotify } from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { FormProps } from "../../shared/types";
import { initInput } from "../../shared/utils";
import { Checkbox, Grid, makeStyles, Typography } from "@material-ui/core";
import { useOptions } from "../../shared/hooks/useOptions";
import { getUser } from "../../authProvider";
import { client } from "../../dataProvider";
import { ToggleButton } from "@material-ui/lab";

const useStyles = makeStyles({
  root: {
    // set margin to nested grid
    "& .MuiGrid-root": {
      padding: "4px",
    },
  }
});

export const WorkPermitForm = (props: FormProps) => {
  const user = getUser();
  const classes = useStyles();

  const [totals, setTotals] = React.useState<any>([]);
  const [totalsReload, setTotalsReload] = useState(false)
  const notify = useNotify();

  function getEmployeeTotalsRemaining(
    date_in: any,
    employee: any,
    force: boolean = false
  ) {
    setTotalsReload(false)
    if (date_in && employee) {
      let date = new Date(date_in);
      client(`./workpermits/get_single_employee_remaining/?date=${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}&employee=${employee}&force=${force}`, {
        method: "GET",
        data: {
        }
      })
        .then((response) => {
          if (response.error) {
            notify(response.error, { type: "error" });
            return
          }
          else {
            setTotals(response.totals)
            setTotalsReload(true)
          }

        })
        .catch((e: any) => {
          notify("Errore", { type: "error" });
        });
    }
  }


  return (
    user ?
      <FormWithRedirect
        {...props}
        render={(formProps: any) => (
          <FormDataConsumer>
            {({ formData }) => (
              <form>

                <Grid container classes={classes} xs={12} style={{ padding: "1em", backgroundColor: "#f4433666" }} >
                  {totalsReload ? console.log(totals) : null}
                  {
                    totalsReload ? (
                      totals.length > 0 ?
                        (totals.map((total: any) => {
                          return (
                            <Grid item xs={12} md={4} lg={3} style={{ border: "1px solid black", borderRadius: "8px", margin: "0px" }}>
                              <Typography variant="body1"><b>{total.name}: {Math.round(total.hours / 8 * 100) / 100} gg ({total.hours} h)</b>  disponibili</Typography>
                              <Typography variant="caption">{total.description}</Typography>
                            </Grid>
                          )
                        })) : <Typography variant="body1">0 ore disponibili</Typography>
                    ) : (formProps.form.getFieldState("date_in") && formProps.form.getFieldState("date_in").value ? "Calcolo giorni rimanenti..." : "Inserire i dati per ottenere i giorni rimanenti")
                  }

                </Grid>
                <Grid container classes={classes} xs={12} style={{ padding: "1em" }} >
                  <Grid item xs={12} md={6}>
                    <ReferenceInput
                      {...initInput("employee", props.fieldOptions, props.canEdit)}
                      reference="autocomplete/employee"
                      source="employee"
                      defaultValue={user.employee}
                      disabled={
                        user.permissions.includes("base.change_workpermit_c1") ||
                          user.permissions.includes("base.change_workpermit_c2") ||
                          user.permissions.includes("base.change_workpermit_c3") ? false : true
                      }
                      onChange={(e) => {
                        getEmployeeTotalsRemaining(formData.date_in, e, formData.force_permit)
                      }}
                    >
                      <AutocompleteInput optionText="label" />
                    </ReferenceInput>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <DateTimeInput {...initInput("date_in", props.fieldOptions, props.canEdit)}
                      onChange={(e) => {
                        if (formProps.form.getFieldState("unit").unit === "D") {
                          if (e.target.valueAsNumber) {
                            let d = new Date();
                            d.setTime(e.target.valueAsNumber);
                            d.setHours(0, 0, 0, 0)
                            formProps.form.change("date_in", d);
                          }
                        }
                        if (e.target.valueAsNumber) {
                          let d1 = new Date();
                          d1.setTime(e.target.valueAsNumber);
                          getEmployeeTotalsRemaining(d1, formData.employee, formData.force_permit)
                        }
                        else {
                          getEmployeeTotalsRemaining(null, formData.employee, formData.force_permit)
                        }

                        formProps.form.change("work_permit_type", "");
                      }}
                      label="Data inizio permesso"
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <SelectInput
                      {...initInput("unit", props.fieldOptions, props.canEdit, "array")}
                      choices={props.fieldOptions.unit.choices}
                      defaultValue={"H"}
                      disabled
                      onChange={(e) => {
                        if (formProps.form.getFieldState("date_in").value && formProps.form.getFieldState("date_in").value.setHours) {
                          if (e.target.value === "D") {
                            formProps.form.change("date_in", new Date(formProps.form.getFieldState("date_in").value.setHours(0, 0, 0, 0)));
                          } else {
                            formProps.form.change("date_in", new Date(formProps.form.getFieldState("date_in").value));
                          }
                        }
                      }} />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    {
                      formProps.form.getFieldState("time_request") ? (formData.unit === "H" ?
                        <SelectInput source={"select_time_request_hours"} choices={[
                          { id: NaN, name: "" },
                          { id: 0.25, name: "0:15" },
                          { id: 0.5, name: "0:30" },
                          { id: 0.75, name: "0:45" },
                          { id: 1, name: "1:00" },
                          { id: 1.25, name: "1:15" },
                          { id: 1.5, name: "1:30" },
                          { id: 1.75, name: "1:45" },
                          { id: 2, name: "2:00" },
                          { id: 2.25, name: "2:15" },
                          { id: 2.5, name: "2:30" },
                          { id: 2.75, name: "2:45" },
                          { id: 3, name: "3:00" },
                          { id: 3.25, name: "3:15" },
                          { id: 3.5, name: "3:30" },
                          { id: 3.75, name: "3:45" },
                          { id: 4, name: "4:00" },
                          { id: 4.25, name: "4:15" },
                          { id: 4.5, name: "4:30" },
                          { id: 4.75, name: "4:45" },
                          { id: 5, name: "5:00" },
                          { id: 5.25, name: "5:15" },
                          { id: 5.5, name: "5:30" },
                          { id: 5.75, name: "5:45" },
                          { id: 6, name: "6:00" },
                          { id: 6.25, name: "6:15" },
                          { id: 6.5, name: "6:30" },
                          { id: 6.75, name: "6:45" },
                          { id: 7, name: "7:00" },
                          { id: 7.25, name: "7:15" },
                          { id: 7.5, name: "7:30" },
                          { id: 7.75, name: "7:45" },
                          { id: 8, name: "8:00" },
                        ]} label={"Seleziona ore"} fullWidth
                          onChange={(e) => {
                            formProps.form.change("time_request", parseFloat(e.target.value) * 60);
                          }}
                          defaultValue={formProps.form.getFieldState("time_request").value / 60}
                        /> :
                        <NumberInput {...initInput("select_time_request_days", props.fieldOptions, props.canEdit)}
                          step={1} min={0} max={31}
                          label={"Seleziona giorni"} fullWidth
                          onChange={(e) => {
                            formProps.form.change("time_request", e.target.value);
                          }}
                          defaultValue={formProps.form.getFieldState("time_request").value}
                        />) : null

                    }
                  </Grid>

                  <Grid item container xs={12} md={6}>
                    <Grid item xs={(user.permissions.includes("base.change_workpermit_c1") ||
                      user.permissions.includes("base.change_workpermit_c2") ||
                      user.permissions.includes("base.change_workpermit_c3")) ? 9 : 12}>
                      <FormDataConsumer>
                        {({ formData }) => {
                          if (formData.date_in && formData.employee) {
                            let date = new Date(formData.date_in);
                            return (
                              <ReferenceInput
                                {...initInput("work_permit_type", props.fieldOptions, props.canEdit)}
                                reference={"autocomplete/workpermittypeemployee"}
                                source="work_permit_type"
                                filter={{
                                  date: date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(),
                                  employee: formData.employee,
                                  force: formData.force_permit
                                }}
                                onChange={(e) => {
                                  totals.map((total: any) => {
                                    if (total.id === e) {
                                      formProps.form.change("unit", total.unit)
                                    }
                                  }
                                  )
                                }
                                }
                              >
                                <AutocompleteInput optionText="label" />
                              </ReferenceInput>
                            )
                          }
                          return null

                        }
                        }
                      </FormDataConsumer>

                    </Grid>
                    <Grid item xs={3} style={{
                      display: (user.permissions.includes("base.change_workpermit_c1") ||
                        user.permissions.includes("base.change_workpermit_c2") ||
                        user.permissions.includes("base.change_workpermit_c3")) ? "" : "none"
                    }}>
                      <BooleanInput resource="force_permit" source="force_permit" label="Forza permesso"
                        onChange={(e) => {
                          formProps.form.change("work_permit_type", "");
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} alignContent="center" style={{ textAlign: "center" }}>
                    <Typography variant="h6">Data fine permesso:</Typography>
                    {
                      formProps.form.getFieldState("date_in") &&
                      formProps.form.getFieldState("time_request") &&

                      <Typography variant="h6">{
                        formProps && formProps.form && formProps.form.getFieldState("date_in") &&
                          formProps.form.getFieldState("date_in").value &&
                          formProps.form.getFieldState("date_in").value.getTime &&
                          formProps.form.getFieldState("time_request") &&
                          formProps.form.getFieldState("time_request").value ? (
                          new Date(formProps.form.getFieldState("date_in").value.getTime() +
                            (formData.unit === "D" ?
                              formProps.form.getFieldState("time_request").value * 60000 * 60 * 24 :
                              formProps.form.getFieldState("time_request").value * 60000)).toLocaleString('it-IT')
                        ) : ""
                      }</Typography>

                    }
                  </Grid>

                  <Grid item xs={12} style={{ display: "none" }}>
                    <NumberInput {...initInput("time_request", props.fieldOptions, props.canEdit)}
                      step={0.25}
                    />
                  </Grid>

                  <TextInput {...initInput("notes_employee", props.fieldOptions, props.canEdit)} />

                  <Grid container style={{
                    width: "100%",
                    display: user.permissions.includes("base.change_workpermit_c1") ||
                      user.permissions.includes("base.change_workpermit_c2") ||
                      user.permissions.includes("base.change_workpermit_c3") ? "" : "none"
                  }}>
                    <TextInput {...initInput("info", props.fieldOptions, props.canEdit)} />
                    <TextInput {...initInput("notes_out", props.fieldOptions, props.canEdit)} />
                    <TextInput {...initInput("description", props.fieldOptions, props.canEdit)} />

                    <Grid item xs={4}>
                      <SelectInput
                        {...initInput("check_1", props.fieldOptions, props.canEdit, "array")}
                        choices={props.fieldOptions.check_1.choices}
                        defaultValue={"W"}
                        disabled={user.permissions.includes("base.change_workpermit_c1") ? false : true}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SelectInput
                        {...initInput("check_2", props.fieldOptions, props.canEdit, "array")}
                        choices={props.fieldOptions.check_2.choices}
                        defaultValue={"W"}
                        disabled={user.permissions.includes("base.change_workpermit_c2") ? false : true}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SelectInput
                        {...initInput("check_3", props.fieldOptions, props.canEdit, "array")}
                        choices={props.fieldOptions.check_3.choices}
                        defaultValue={"W"}
                        disabled={user.permissions.includes("base.change_workpermit_c3") ? false : true}
                      />
                    </Grid>
                    <ReferenceInput
                      {...initInput("refuse_reason", props.fieldOptions, props.canEdit)}
                      reference="autocomplete/refusereason"
                      source="refuse_reason"
                    >
                      <AutocompleteInput optionText="label" />
                    </ReferenceInput>
                  </Grid>
                </Grid>
                <CustomToolbar
                  canDelete={getUser().is_admin === true ? props.canDelete : false}
                  canEdit={props.canEdit}
                  resource={props.resource}
                  pristine={formProps.pristine}
                  record={formProps.record}
                  mutationMode={props.mutationMode}
                  invalid={formProps.invalid}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  handleSubmit={formProps.handleSubmit}
                  saving={formProps.saving}
                  basePath={props.basePath}
                  hide_3={!(user.permissions.includes("base.change_workpermit_c1") ||
                    user.permissions.includes("base.change_workpermit_c2") ||
                    user.permissions.includes("base.change_workpermit_c3"))}
                />
              </form>
            )}
          </FormDataConsumer >
        )} /> : null
  );
};
