import { useEffect, useRef } from "react";
//import UTIF from "utif";
import { UTIF } from "./UTIF_MOD";
import { url } from "../../constants";
import { clientNoJson } from "../../dataProvider";
import Panzoom from "@panzoom/panzoom";

interface Props {
  src: string;
  rotation: number;
}
export const NoteImage = ({ src, rotation }: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const cnv = canvasRef.current;
    if (cnv) {
      clientNoJson(url + src, { responseType: "arraybuffer" }).then(
        (response) => {
          const ifds = UTIF.decode(response.data);
          UTIF.decodeImage(response.data, ifds[0]);
          const rgba = UTIF.toRGBA8(ifds[0]),
            w = ifds[0].width,
            h = ifds[0].height;
          const imgd = new ImageData(new Uint8ClampedArray(rgba.buffer), w, h);
          cnv.width = w;
          cnv.height = h;
          const ctx = cnv.getContext("2d");
          ctx && ctx.putImageData(imgd, 0, 0);
          const panzoom = Panzoom(cnv);
          cnv.parentElement?.addEventListener("wheel", function (event) {
            if (!event.shiftKey) return;
            panzoom?.zoomWithWheel(event);
          });
        }
      );
    }
  }, [src]);

  return <canvas style={{ width: "100%", height: "100%", transform: `rotate(${rotation}deg)` }} ref={canvasRef} />;
};
