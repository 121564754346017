import {
  useTheme,
  makeStyles,
  Slider,
} from "@material-ui/core";
import { useState, useMemo, useCallback, useEffect, useRef } from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateInput,
  ListProps,
  NullableBooleanInput,
  NumberField,
  Record,
  RecordContextProvider,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  TextField,
  TextInput,
} from "react-admin";
import { CustomList, CustomListProps } from "../../components/CustomList";
import { dateFormatter, getDefaultListProps, initField, subtractMonth } from "../../shared/utils";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { useOptions } from "../../shared/hooks/useOptions";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { TariffType } from "../../shared/types";
import { BulkActions } from "./BulkActions";
import { VerifiedPurchaseChargesSummaryDatagrid } from "./VerifiedPurchaseChargesSummaryDatagrid";
import { TotalTaxableHeader } from "./TotalTaxableHeader";


const beforeDate = dateFormatter(new Date());
const afterDate = dateFormatter(subtractMonth(new Date(), 1));


export const VerifiedPurchaseChargesSummaryList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const { data: optionsTariff } = useOptions("tariffs", "GET");
  const { data: optionsDeliveryStates } = useOptions("delivery-states", "GET");

  const filters =
    options && optionsTariff && optionsDeliveryStates
      ? [
        <DateInput
          source="document_date_after"
          label="Data documento dal"
          alwaysOn
          className="filter-field-small"
        />,
        <DateInput
          source="document_date_before"
          label="Data documento al"
          alwaysOn
          required
          className="filter-field-small"
        />,
        <SelectArrayInput
          {...initField("delivery_last_status", optionsDeliveryStates, "array")}
          choices={optionsDeliveryStates.status.choices}
          label="Stato"
          alwaysOn
        />,
        <DateInput
          source="charge_date_after"
          label="Dal"
          className="filter-field-small"
        />,
        <DateInput
          source="charge_date_before"
          label="Al"
          className="filter-field-small"
        />,
        <TextInput
          source="document_number"
          label="N. documento"
          className="filter-field-small"
        />,
        <NullableBooleanInput
          source="zero_charges"
          label="Nulli"
          className="filter-field-small"
        />,
        <DateInput
          source="delivery_date_after"
          label="Data consegna dal"
          className="filter-field-small"
        />,
        <DateInput
          source="delivery_date_before"
          label="Data consegna al"
          className="filter-field-small"
        />,
        <ReferenceInput
          {...initField("delivery", options)}
          reference="autocomplete/delivery"
          alwaysOn
          className="filter-field-small"
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>,
        <ReferenceInput
          {...initField("delivery__main_delivery", options)}
          reference="autocomplete/main_delivery"
          label="Missione principale"
          alwaysOn
          className="filter-field-small"
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>,
        <ReferenceInputSubject alwaysOn
          {...initField("client", options)}
          filter={{ type: "client" }}
          className="filter-field-small"
        />,
        <ReferenceInputSubject alwaysOn
          {...initField("supplier", options)}
          filter={{ type: "supplier" }}
          className="filter-field-small"
        />,
        <SelectArrayInput
          style={{ minWidth: "155px" }}
          choices={optionsTariff.charge_type.choices}
          {...initField("charge_type", optionsTariff, "array")}
        />,
        <ReferenceInputSubject alwaysOn
          {...initField("receiver", options)}
          filter={{ type: "receiver" }}
          className="filter-field-small"
        />,
        <ReferenceInputSubject alwaysOn
          {...initField("sender", options)}
          className="filter-field-small"
        />,
        <ReferenceArrayInput
          helperText={false}
          fullWidth
          source="items"
          reference="autocomplete/item"
          label="Articoli"

        >
          <AutocompleteArrayInput optionText="label" resettable />
        </ReferenceArrayInput>,
        <ReferenceArrayInput
          helperText={false}
          fullWidth
          source="not_items"
          reference="autocomplete/item"
          label="NON contiene Articoli"

        >
          <AutocompleteArrayInput optionText="label" resettable />
        </ReferenceArrayInput>,
        <NullableBooleanInput
          source="reconciliation_date_not_null"
          label="Riconciliati"
          className="filter-field-small"
          alwaysOn
        />,
      ]
      : [];

  return (
    options ?
      <CustomList
        filters={filters}
        {...getDefaultListProps(props)}
        sort={{ field: "charge_date", order: "ASC" }}
        pagination={false}
        filter={{
          verified: true,
          no_invoice: true,
          //no_page: true,
          tariff_type: TariffType.PURCHASE,
        }}
        filterDefaultValues={{
          document_date_before: beforeDate,
          reconciliation_date_not_null: false,
        }}
        bulkActionButtons={<BulkActions />}
        topElement={<TotalTaxableHeader/>}
      >
        <VerifiedPurchaseChargesSummaryDatagrid />
      </CustomList> :
      null
  );
};
