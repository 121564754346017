import {
  Button,
  Datagrid,
  DatagridProps,
  FunctionField,
  NumberField,
  Record,
} from "react-admin";

import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { initField } from "../../shared/utils";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import { Typography } from "@material-ui/core";
import { TariffType } from "../../shared/types";


export const PurchaseChargesSummaryDatagrid = (props: DatagridProps & { beforeDate: string | undefined }) => {
  const { data: options } = useOptionsResource("GET");

  return options ? (
    <Datagrid
    >
      <FunctionField
        tabIndex={-1}
        sortable={false}
        label="Fornitore"
        render={(tableRecord: Record | undefined) => {
          if (tableRecord) {
            return (
              <Button
                tabIndex={-1}
                //label={`${tableRecord.id}`}
                onClick={() => {
                  let urlParams: any = new URLSearchParams(window.location.search);
                  if (urlParams.has("filter")) {
                    let filter = urlParams.get("filter");
                    let json_filter = JSON.parse(filter)
                    json_filter.supplier = tableRecord.id
                    let json_string = JSON.stringify(json_filter)
                    json_string = json_string.replace(`"${tableRecord.id}"`, `${tableRecord.id}`)
                    urlParams.set("filter", json_string)
                  }
                  else {
                    let json_string = JSON.stringify({
                      "supplier": tableRecord.id,
                      "verified": false,
                      "no_invoice": true,
                      "tariff_type": TariffType.PURCHASE,
                      "document_date_before": props.beforeDate,
                      "delivery_last_status": ['D', "SE", "ID", "L", "G", "S", "T"],
                      "page": 2000
                    })
                    json_string = json_string.replace(`"${tableRecord.id}"`, `${tableRecord.id}`)
                    urlParams.set("filter", json_string)
                  }
                  window.open("/charges-a?" + urlParams.toString())

                }}
              >
                <Typography variant={"body2"}>{`${tableRecord.supplier__business_name} (${tableRecord.id})`}</Typography>
              </Button>
            );
          }
          return null;
        }}
      />

      <NumberField sortable={false} {...initField("taxable", options)} />

    </Datagrid>
  ) : null;
};
