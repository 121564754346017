import { useEffect, useRef, useState } from "react";
import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import "./MapControls.css";
import blueIconImg from "../assets/marker-blue.png";
import grayIconImg from "../assets/marker-gray.png";
import yellowIconImg from "../assets/marker-yellow.png";
import redIconImg from "../assets/marker-red.png";
import greenIconImg from "../assets/marker-green.png";
import skyIconImg from "../assets/marker-sky.png";
import purpleIconImg from "../assets/marker-purple.png";
import fuchsiaIconImg from "../assets/marker-fuchsia.png";
import orangeIconImg from "../assets/marker-orange.png";
import endIconImg from "../assets/marker-end.png"
import startIconImg from "../assets/marker-start.png"
import startZoomIconImg from "../assets/marker-s-p.png"
import underlineIcon from "../assets/underlineIcon.png";
import noteDotIcon from "../assets/noteDotIcon.png";

export const MapLegend = () => {
  const context = useLeafletContext();
  const legendControl = useRef<L.Control>();
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (context.map) {
      legendControl.current = new L.Control({ position: "topleft" });

      legendControl.current.onAdd = () => {
        const div = L.DomUtil.create("div", "info-legend");

        div.innerHTML = `<div style="display:flex;justify-content:space-between;align-items:center;gap:20px"><span><h4>Legenda</h4></span><span>${show ? "&#x25B2;" : "&#x25BC;"
          }</span></div>
        <div class="legend-container ${show ? "" : "hide-control"}">
        <div><i><img src="${grayIconImg}" height="30px"/></i><span>Punto partenza merce</span></div>
        <div><i><img src="${blueIconImg}" height="30px"/></i><span>Missione consegna</span></div>
        <div><i><img src="${skyIconImg}" height="30px"/></i><span>Missione riconsegna</span></div>
        <div><i><img src="${redIconImg}" height="30px"/></i><span>Missione ritiro</span></div>
        <div><i><img src="${orangeIconImg}" height="30px"/></i><span>Missione reso</span></div>
        <div><i><img src="${yellowIconImg}" height="30px"/></i><span>Missione segnaposto</span></div>
        <div><i><img src="${greenIconImg}" height="30px"/></i><span>Punto selezionato</span></div>
        <div><i><img src="${startZoomIconImg}" height="30px"/></i><span>Partenza del punto centrato dal mouse</span></div>
        <div><i><img src="${startIconImg}" height="30px"/></i><span>Punto iniziale giro</span></div>
        <div><i><img src="${endIconImg}" height="30px"/></i><span>Punto finale giro</span></div>
        <div><i><img src="${fuchsiaIconImg}" height="30px"/></i><span>Contiene missioni con più di 3 tipi</span></div>
        <div><i><img src="${purpleIconImg}" height="30px"/></i><span>Contiene punti collegati a quelli aggiunti</span></div>
        <div><i><img src="${underlineIcon}" height="30px"/></i><span>Extra peso/colli</span></div>
        <div><i><img src="${noteDotIcon}" height="30px"/></i><span>Contiene note operative</span></div>
        </div>`;
        div.onclick = () => setShow((value) => !value);
        return div;
      };

      legendControl.current.addTo(context.map);
      return () => {
        legendControl.current &&
          context.map.removeControl(legendControl.current);
      };
    }
  }, [context.map, show]); //here add map
  return null;
};
