import { AutocompleteInput, BulkDeleteButton, Datagrid, DateField, DateInput, DateTimeInput, FunctionField, ListProps, NumberField, NumberInput, Record, ReferenceInput, SelectArrayInput, SelectField, SelectInput, TextField, TextInput, useListContext, useNotify } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField, initInput } from "../../shared/utils";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { EmployeeField } from "../../components/EmployeeField";
import { getUser } from "../../authProvider";
import { UpdateChecksBulkButton } from "./UpdateChecksBulkButton";
import { de } from "date-fns/locale";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import { client } from "../../dataProvider";
import { useMemo, useState } from "react";


const useStyles = makeStyles({
  root: {
    // set margin to nested grid
    "& .MuiGrid-root": {
      padding: "4px",
    },
  }
});

const WorkPermitEmployeListHeader = (props: any) => {
  const user = getUser();
  const classes = useStyles();
  const { filterValues } = useListContext();

  const [totals, setTotals] = useState<any>([]);
  const [totalsReload, setTotalsReload] = useState(false)
  const notify = useNotify();

  useMemo(() => {
    if (user.employe || filterValues.employee) {
      let date = new Date();
      client(`./workpermits/get_single_employee_remaining/?date=${filterValues.date_in_after ? filterValues.date_in_after : (date.getFullYear() + "-" + date.getMonth() + 1 + "-" + date.getDate())
        }&employee=${filterValues.employee ? filterValues.employee : user.employee
        }&force=true&`, {
        method: "GET",
        data: {
        }
      })
        .then((response) => {
          if (response.error) {
            notify(response.error, { type: "error" });
            return
          }
          else {
            setTotals(response.totals)
            setTotalsReload(true)
          }

        })
        .catch((e: any) => {
          notify("Errore", { type: "error" });
        });
    }
  }, [filterValues])

  //getEmployeeTotalsRemaining(new Date(), user.employee);

  return (
    <Grid container classes={classes} xs={12} style={{ padding: "1em", backgroundColor: "#f4433666" }} >
      {
        totalsReload ? (
          totals.length > 0 ?
            (totals.map((total: any) => {
              return (
                <Grid item xs={12} md={4} lg={3} style={{ border: "1px solid black", borderRadius: "8px", margin: "0px" }}>
                  <Typography variant="body1"><b>{total.name}: {Math.round(total.hours / 8 * 100) / 100} gg ({total.hours} h)</b>  disponibili</Typography>
                  <Typography variant="caption">{total.description}</Typography>
                </Grid>
              )
            })) : <Typography variant="body1">0 ore disponibili ad oggi</Typography>
        ) : "Calcolo giorni rimanenti..."
      }

    </Grid>
  )
}




const BulkActionButtons = (props: any) => {
  const user = getUser();
  return (<>
    {user.is_admin === true ? <BulkDeleteButton {...props} /> : null}
    {user.permissions.includes("base.change_workpermit_c1") ? <UpdateChecksBulkButton {...props} check="check_1" label="A" /> : null}
    {user.permissions.includes("base.change_workpermit_c2") ? <UpdateChecksBulkButton {...props} check="check_2" /> : null}
    {user.permissions.includes("base.change_workpermit_c3") ? <UpdateChecksBulkButton {...props} check="check_3" /> : null}
  </>
  )
};

export const WorkPermitList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const user = getUser();
  const red = "#ff0000";
  const green = "#00ff00";
  const gray = "#808080";


  const filters = options
    ? [
      <NumberInput {...initField("id", options)} label="ID" alwaysOn />,
      <ReferenceInput
        reference="autocomplete/workpermittype"
        source={"work_permit_type"}
        label="Tipo"
        className="filter-field-small"
        alwaysOn>
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <DateInput
        {...initField("date_in_after", options)}
        label="Data inizio da"
      />,
      <DateInput
        {...initField("date_in_before", options)}
        label="Data inizio a"
      />,
      <DateTimeInput
        {...initField("date_center_after", options)}
        label="Data contenuta da"
        alwaysOn
      />,
      <DateTimeInput
        {...initField("date_center_before", options)}
        label="Data contenuta a"
        alwaysOn
      />,
      <ReferenceInput
        reference="autocomplete/employee"
        source={"employee"}
        label="Dipendente"
        className="filter-field-small"
        alwaysOn>
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <SelectArrayInput
        {...initField("check_1", options, "array")}
        choices={options.check_1.choices}
        label="Check Disponibilità ore"
        className="filter-field-small"
      />,
      <SelectArrayInput
        {...initField("check_2", options, "array")}
        choices={options.check_2.choices}
        label="Check Disponibilità turni"
        className="filter-field-small"
      />,
      <SelectArrayInput
        {...initField("check_3", options, "array")}
        choices={options.check_3.choices}
        label="Check Convalida"
        className="filter-field-small"
      />,
    ]
    : [];

  return options ? (
    <CustomList {...getDefaultListProps(props)}
      filters={filters}
      bulkActionButtons={
        user.permissions.includes("base.change_workpermit_c1") &&
          user.permissions.includes("base.change_workpermit_c2") &&
          user.permissions.includes("base.change_workpermit_c3") ?
          <BulkActionButtons /> : false
      }
      sort={{ field: "id", order: "DESC" }}
      topElement={<WorkPermitEmployeListHeader />}
    >
      <Datagrid>
        <IdentifierField {...initField("id", options)} />
        {
          user.permissions.includes("base.change_workpermit_c1") &&
          user.permissions.includes("base.change_workpermit_c2") &&
          user.permissions.includes("base.change_workpermit_c3") &&
          <EmployeeField {...initField("employee_object", options)} label="Operatore" />
        }
        <TextField {...initField("work_permit_type_label", options)} label="Tipo" />
        <DateField {...initField("date_in", options)} />
        <DateField {...initField("date_in", options)}
          showTime
          options={{ hour: '2-digit', minute: '2-digit' }} />
        <DateField {...initField("date_out", options)} />
        <DateField {...initField("date_out", options)} showTime
          options={{ hour: '2-digit', minute: '2-digit' }} />
        <FunctionField
          sortBy="time_request_minutes"
          source="time_request"
          label="Richiesta"
          render={(tableRecord: Record | undefined) => {
            if (tableRecord) {
              return <Typography align="right">{
                tableRecord.unit === "D" ?
                  tableRecord.time_request : (
                    (Math.floor(tableRecord.time_request / 60)).toString() + ":" + (tableRecord.time_request % 60).toString().padEnd(2, "0")
                  )
              }
              </Typography>
            }
            else {
              return null;
            }
          }}
        />
        <SelectField {...initField("unit", options, "array")} choices={options.unit.choices} />
        {
          user.permissions.includes("base.change_workpermit_c1") &&
          <FunctionField
            sortBy="check_1"
            source="check_1"
            label={"Check disponibilità ore"}
            render={(tableRecord: Record | undefined) => {
              if (!tableRecord) {
                return null;
              }
              let color = gray;
              switch (tableRecord.check_1) {
                case "A":
                  color = green;
                  break;
                case "R":
                  color = red;
                  break;
                default:
                  color = gray;
                  break;
              }

              return (
                <div style={{
                  borderRadius: "50%",
                  width: "15px",
                  height: "15px",
                  padding: "3px",
                  background: color,
                  //border: "1px solid #000",
                  color: "#000",
                  textAlign: "center",
                  font: "14px Arial, sans-serif",
                  cursor: "pointer"
                }}
                >
                  <b>{" "}</b>
                </div>
              );
              return null;
            }}
          />
        }

        {
          user.permissions.includes("base.change_workpermit_c2") &&
          <FunctionField
            sortBy={"check_2"}
            source="check_2"
            label={"Check disponibilità turni"}
            render={(tableRecord: Record | undefined) => {
              if (!tableRecord) {
                return null;
              }
              let color = gray;
              switch (tableRecord.check_2) {
                case "A":
                  color = green;
                  break;
                case "R":
                  color = red;
                  break;
                default:
                  color = gray;
                  break;
              }

              return (
                <div style={{
                  borderRadius: "50%",
                  width: "15px",
                  height: "15px",
                  padding: "3px",
                  background: color,
                  //border: "1px solid #000",
                  color: "#000",
                  textAlign: "center",
                  font: "14px Arial, sans-serif",
                  cursor: "pointer"
                }}
                >
                  <b>{" "}</b>
                </div>
              );
              return null;
            }}
          />
        }

        {

          <FunctionField
            sortBy="check_3"
            source="check_3"
            label={"Accett."}
            render={(tableRecord: Record | undefined) => {
              if (!tableRecord) {
                return null;
              }
              let color = gray;
              switch (tableRecord.check_3) {
                case "A":
                  color = green;
                  break;
                case "R":
                  color = red;
                  break;
                default:
                  color = gray;
                  break;
              }

              return (
                <div style={{
                  borderRadius: "50%",
                  width: "15px",
                  height: "15px",
                  padding: "3px",
                  background: color,
                  //border: "1px solid #000",
                  color: "#000",
                  textAlign: "center",
                  font: "14px Arial, sans-serif",
                  cursor: "pointer"
                }}
                >
                  <b>{" "}</b>
                </div>
              );
              return null;
            }}
          />
        }
        <TextField {...initField("refuse_reason_label", options)} />

        {
          (user.permissions.includes("base.change_workpermit_c1") ||
            user.permissions.includes("base.change_workpermit_c2") ||
            user.permissions.includes("base.change_workpermit_c3")) &&
          <FunctionField
            source="counters"
            label="Contatori"
            render={(tableRecord: Record | undefined) => {
              if (tableRecord && tableRecord.counters) {
                return tableRecord.counters.map((counter: any) => {
                  return (
                    <div>
                      <Typography>{counter.work_permit_rule.description}: {Math.round(counter.hours / 8 * 100) / 100} gg ({counter.hours} h)</Typography>
                    </div>
                  )
                })
              }
              else {
                return null;
              }
            }}
          />

        }


        {
          /*
          user.permissions.includes("base.change_workpermit_c1") &&
          <SelectField {...initField("check_1", options, "array")} choices={options.check_1.choices} />
        
          user.permissions.includes("base.change_workpermit_c2") &&
          <SelectField {...initField("check_2", options, "array")} choices={options.check_2.choices} />
        
        <SelectField {...initField("check_3", options, "array")} choices={options.check_3.choices} />
        */
        }

      </Datagrid>
    </CustomList>
  ) : null;
};
