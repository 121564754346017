import { AutocompleteInput, Button, ListProps, useNotify } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField, initInput } from "../../shared/utils";
import { Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { EmployeeField } from "../../components/EmployeeField";
import { client } from "../../dataProvider";
import { useMemo, useRef, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { ca, de } from "date-fns/locale";


const useStyles = makeStyles({
  root: {
    "& input": {
      height: "30px",
    },
    "& th": {
      borderRight: "0px solid lightgray",
      backgroundColor: "#fddfdd",
      padding: "10px 0px",
      fontSize: "1.2em",
      textAlign: "center",
    },
    "& td": {
      borderRight: "1px solid lightgray",
      padding: "4px 0px",
    },
    "& tr": {

      '&:hover': {
        backgroundColor: '#d1dfd1 !important' // fifth bg color
      }
    },

  }
});


export const WorkPermitTotalsList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const refSerarchBar = useRef(null);
  const classes = useStyles();

  const [loadingEmplyees, setLoadingEmplyees] = useState(true);
  const [employeesList, setEmployeesList] = useState<any>([]);
  const [employee, setEmployee] = useState<any>();
  const [loadingData, setLoadingData] = useState(true);
  const notify = useNotify();

  const [updateStats, setUpdateStats] = useState<any>(1);
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [months, setMonths] = useState<any>([]);
  const [tableHeight, setTableHeight] = useState<any>(150);

  const [data, setData] = useState<any>({});

  function loadEmpoyessList() {
    setLoadingEmplyees(true)
    client(`employees/get_all_employees`, {
      method: "GET",
      data: {
      }
    })
      .then((response) => {
        if (response.error) {
          notify(response.error, { type: "error" });
          return
        }
        else {
          let employeesTemp = []
          for (let e in response) {
            employeesTemp.push({
              id: response[e].id,
              name: response[e].name,
              surname: response[e].surname,
              label: response[e].id + ": " + response[e].name + " " + response[e].surname
            })
          }
          setEmployeesList(employeesTemp)
          setLoadingEmplyees(false)
        }

      })
      .catch((e: any) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoadingEmplyees(false)
      });
  }

  function reloadList() {

    setLoadingData(true)
    client(`./workpermits/get_totals/?start_date=${startDate}&end_date=${endDate}&employee=${employee ? employee : ""}`, {
      method: "GET",
    }, true)
      .then((response) => {
        if (response.error) {
          notify(response.error, { type: "error" });
          return
        }
        else {
          console.log(response)
          setData(response)
          setLoadingData(false)
        }

      })
      .catch((e: any) => {
        notify("Errore", { type: "error" });
      })
      .finally(() => {
        setLoadingData(false)
      });
  }

  useMemo(() => {
    loadEmpoyessList()
    reloadList()
  }, [])

  function getTypeString(work_permit_type: string) {
    switch (work_permit_type) {
      case "H":
        return "Ferie"
      case "S":
        return "Malattia"
      case "P":
        return "Permessi"
      default:
        return "Altro"
    }
  }


  function getContractHours(work_permit_type: string, d: any) {
    switch (work_permit_type) {
      case "H":
        return d["holiday_hours"]
        break;
      case "S":
        return d["sick_hours"]
        break;
      case "P":
        return d["permissions_hours"]
        break;
      default:
        return 0
    }
  }

  function getAlertColor(perm: number, tot: number) {
    let ratio = perm / tot;
    if (ratio > 1) {
      return "rgba(255, 0, 0, 0.5)"
    }
    else if (ratio > 0.7) {
      return "rgba(255, 255, 0, 0.5)"
    }
    else {
      return "rgba(0, 255, 0, 0.2)"
    }
  }

  return (
    <>
      <div style={{
        display: (loadingData || loadingEmplyees) ? "block" : "none",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        zIndex: 999,
      }}>
        <Typography style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "2em",
        }}>
          Loading...
        </Typography>
      </div>

      <div onKeyDown={(e) => {
        if (e.key === "Enter") {
          reloadList()
        }
      }}>
        {employeesList &&
          <Grid container
            ref={refSerarchBar}
            style={{
              padding: "10px",
              marginRight: "30px",
              width: "100%"
            }}>
            <TextField
              style={
                {
                  width: "300px",
                  marginRight: "10px"
                }
              }
              className={classes.root}

              id="start_date"
              label="Da"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value)
              }}
            />
            <TextField
              style={
                {
                  width: "300px",
                  marginRight: "10px"
                }
              }
              className={classes.root}
              id="end_date"
              label="A"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value)
              }}
            />
            <Autocomplete
              style={
                {
                  width: "400px",
                  marginRight: "10px"
                }
              }
              multiple
              id="months"
              options={[
                { id: 1, label: 'Gennaio' },
                { id: 2, label: 'Febbraio' },
                { id: 3, label: 'Marzo' },
                { id: 4, label: 'Aprile' },
                { id: 5, label: 'Maggio' },
                { id: 6, label: 'Giugno' },
                { id: 7, label: 'Luglio' },
                { id: 8, label: 'Agosto' },
                { id: 9, label: 'Settembre' },
                { id: 10, label: 'Ottobre' },
                { id: 11, label: 'Novembre' },
                { id: 12, label: 'Dicembre' },
              ]}
              getOptionLabel={(option: any) => option.label}
              filterSelectedOptions
              onChange={(event: any, newValue: any) => {
                let tempMonths = []
                for (let e in newValue) {
                  tempMonths.push(newValue[e].id)
                }
                tempMonths = tempMonths.sort(function (a, b) { return a - b })
                setMonths(tempMonths)
              }}

              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleziona mesi"
                />
              )}
            />
            <Autocomplete
              style={
                {
                  width: "400px",
                  marginRight: "10px"
                }
              }
              id="tags-outlined"
              options={employeesList}
              getOptionLabel={(option: any) => option.label}
              onChange={(_, v) => setEmployee(v?.id)}

              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleziona dipendente"
                />
              )}
            />
            <Button
              style={
                {
                  height: "55px",
                  width: "100px",
                  marginRight: "10px"
                }
              }
              onClick={() => {
                reloadList()
              }}
              label=""
              color="primary"
              variant="contained"
            >
              <Typography>Filtra</Typography>
            </Button>
          </Grid>
        }

        <div style={{
          position: "relative",
          overflowX: "scroll",
          overflowY: "scroll",
          height: "calc(100vh - " + tableHeight + "px)",
          width: "100%",
        }}>
          <Table className={classes.root}>
            <TableHead style={{
              position: "sticky",
              top: 0,
              boxShadow: "1px 1px 1px grey",
              borderRight: "1px solid black",
              zIndex: 999,
            }}>
              <TableRow>
                <TableCell>Operatore</TableCell>
                <TableCell>Ferie</TableCell>
                <TableCell>Permessi</TableCell>
                <TableCell>Malattia</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.totals &&
                Object.keys(data.totals).map((employee_id: any) => {
                  let row = data.totals[employee_id]
                  return (
                    <TableRow >
                      <TableCell>
                        <Typography>{employee_id}:{row.surname} {row.name}</Typography>
                      </TableCell>
                      <TableCell style={{ width: "300px", backgroundColor: getAlertColor(row["H"] / 60, getContractHours("H", data.contracts[employee_id])) }}>
                        <Typography>
                          <Grid container>
                            <Grid item xs={5}>
                              <Typography align="center">{Math.floor(row["H"] / 60)}:{(row["H"] % 60).toString().padStart(2, "0")}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography align="center">/</Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography align="center">{getContractHours("H", data.contracts[employee_id])}:00
                              </Typography>
                            </Grid>
                          </Grid>
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: "300px", backgroundColor: getAlertColor(row["P"] / 60, getContractHours("P", data.contracts[employee_id])) }}>
                        <Typography>
                          <Grid container>
                            <Grid item xs={5}>
                              <Typography align="center">{Math.floor(row["P"] / 60)}:{(row["P"] % 60).toString().padStart(2, "0")}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography align="center">/</Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography align="center">{getContractHours("P", data.contracts[employee_id])}:00
                              </Typography>
                            </Grid>
                          </Grid>
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: "300px", backgroundColor: getAlertColor(row["S"] / 60, getContractHours("S", data.contracts[employee_id])) }}>
                        <Typography>
                          <Grid container>
                            <Grid item xs={5}>
                              <Typography align="center">{Math.floor(row["S"] / 60)}:{(row["S"] % 60).toString().padStart(2, "0")}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography align="center">/</Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography align="center">{getContractHours("S", data.contracts[employee_id])}:00
                              </Typography>
                            </Grid>
                          </Grid>
                        </Typography>
                      </TableCell>

                    </TableRow>
                  )


                })}
            </TableBody>
          </Table>

        </div>
      </div >
    </>
  );
};