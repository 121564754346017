import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  ListProps,
  ReferenceInput,
  SelectArrayInput,
  TextInput,
} from "ra-ui-materialui";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { initField } from "../../shared/utils";
import { CalculateChargesButton } from "./components/CalculateChargesButton";
import { useOptions } from "../../shared/hooks/useOptions";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { BulkActions } from "./components/BulkActions";
import { ChargesList } from "../components/ChargesList";
import { TariffType } from "../../shared/types";
import { AutocompleteArrayInput, NullableBooleanInput, ReferenceArrayInput } from "react-admin";

export const CalculateChargesList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const { data: optionsTariff } = useOptions("tariffs", "GET");
  const { data: optionsDeliveryStates } = useOptions("delivery-states", "GET");

  const filters =
    options && optionsTariff && optionsDeliveryStates
      ? [
        <DateInput
          source="document_date_after"
          label="Data documento dal"
          alwaysOn
          className="filter-field-small"
        />,
        <DateInput
          source="document_date_before"
          label="Data documento al"
          alwaysOn
          required
          className="filter-field-small"
        />,
        <SelectArrayInput
          {...initField("delivery_last_status", optionsDeliveryStates, "array")}
          choices={optionsDeliveryStates.status.choices}
          label="Stato"
          alwaysOn
        />,
        <DateInput
          source="charge_date_after"
          label="Dal"
          className="filter-field-small"
        />,
        <DateInput
          source="charge_date_before"
          label="Al"
          className="filter-field-small"
        />,
        <TextInput
          source="document_number"
          label="N. documento"
          className="filter-field-small"
        />,
        <NullableBooleanInput
          source="zero_charges"
          label="Nulli"
          className="filter-field-small"
        />,
        <DateInput
          source="delivery_date_after"
          label="Data consegna dal"
          className="filter-field-small"
        />,
        <DateInput
          source="delivery_date_before"
          label="Data consegna al"
          className="filter-field-small"
        />,
        <ReferenceInput
          {...initField("delivery", options)}
          reference="autocomplete/delivery"
          alwaysOn
          className="filter-field-small"
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>,
        <ReferenceInput
          {...initField("delivery__main_delivery", options)}
          reference="autocomplete/main_delivery"
          label="Missione principale"
          alwaysOn
          className="filter-field-small"
        >
          <AutocompleteInput optionText="label" />
        </ReferenceInput>,
        <ReferenceInputSubject
          {...initField("client", options)}
          filter={{ type: "client" }}
          className="filter-field-small"
        />,
        <SelectArrayInput
          style={{ minWidth: "155px" }}
          choices={optionsTariff.charge_type.choices}
          {...initField("charge_type", optionsTariff, "array")}
          className="filter-field-small"
        />,
        <ReferenceArrayInput
          helperText={false}
          fullWidth
          source="clients"
          filter={{ type: "client" }}
          reference="autocomplete/subject"
          label="Clienti"
          alwaysOn
        >
          <AutocompleteArrayInput optionText="label" resettable />
        </ReferenceArrayInput>,
        <ReferenceArrayInput
          helperText={false}
          fullWidth
          source="suppliers"
          filter={{ type: "supplier" }}
          reference="autocomplete/subject"
          label="Fornitori"
          alwaysOn
        >
          <AutocompleteArrayInput optionText="label" resettable />
        </ReferenceArrayInput>,
        <ReferenceInputSubject alwaysOn
          {...initField("receiver", options)}
          filter={{ type: "receiver" }}
          className="filter-field-small"
        />,
        <ReferenceInputSubject alwaysOn
          {...initField("sender", options)}
          className="filter-field-small"
        />,
        <ReferenceArrayInput
          helperText={false}
          fullWidth
          source="items"
          reference="autocomplete/item"
          label="Articoli"

        >
          <AutocompleteArrayInput optionText="label" resettable />
        </ReferenceArrayInput>,
        <ReferenceArrayInput
          helperText={false}
          fullWidth
          source="not_items"
          reference="autocomplete/item"
          label="NON contiene Articoli"

        >
          <AutocompleteArrayInput optionText="label" resettable />
        </ReferenceArrayInput>,
      ]
      : [];

  return (
    <ChargesList
      {...props}
      Actions={BulkActions}
      filters={filters}
      filter={{
        verified: false,
        no_invoice: true,
        //no_page: true,
        tariff_type: TariffType.SALE
      }}
      sort={{ field: "id", order: "DESC" }}
    //additionalActions={<CalculateChargesButton />}
    />
  );
};
