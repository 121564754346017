import {
  useTheme,
  makeStyles,
} from "@material-ui/core";
import { useState } from "react";
import {
  Record,
} from "react-admin";
import { CustomList, CustomListProps } from "../../../components/CustomList";

import {
  dateFormatter,
  subtractMonth,
  getDefaultListProps,
} from "../../../shared/utils";
import { ClientGroupedChargeDatagrid } from "./ClientGroupedChargeDatagrid";
import { ChargeAside } from "../ChargeAside";


const beforeDate = dateFormatter(new Date());
const afterDate = dateFormatter(subtractMonth(new Date(), 1));

const useStyles = makeStyles((theme) => ({ paper: { height: "100000000px" } }));


export const ChargesList = (
  props: Omit<CustomListProps, "children"> & { Actions: React.ElementType }
) => {
  const [activeCharge, setActiveCharge] = useState<Record>();
  const theme = useTheme();

  const rowStyle = (record: Record, index: number) => ({
    backgroundColor: record.charges.find(
      (charge: Record) => charge.id === activeCharge?.id
    )
      ? theme.palette.primary.light
      : "white",
  });

  const { Actions, ...rest } = props;

  var lmc = document.getElementById("layout-main-content");
  var appbarMain = document.getElementById("appbar-main");
  if (lmc) {
    //lmc.style.overflowY = "hidden"
  }
  if (appbarMain) {
    appbarMain.style.display = "none"
  }

  return (
    <CustomList
      {...getDefaultListProps(rest)}
      aside={
        <ChargeAside
          closeAside={() => setActiveCharge(undefined)}
          activeCharge={activeCharge}
        />
      }
      sort={{ field: "charge_date", order: "ASC" }}
      perPage={1000}
      filterDefaultValues={{
        document_date_before: beforeDate,
        delivery_last_status: ['D', "SE", "ID", "L", "G", "S", "T"],
      }}
    >
      <ClientGroupedChargeDatagrid
        Actions={Actions}
        activeCharge={activeCharge}
        rowStyle={rowStyle}
        setActiveCharge={setActiveCharge}
      />
    </CustomList>
  );
};
