import { AutocompleteInput, Button, Datagrid, DateField, DateTimeInput, FormField, FunctionField, ListProps, NumberInput, Record, ReferenceInput, SelectField, SelectInput, SimpleForm, TextField, TextInput, useListContext, useResourceContext } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField, initInput } from "../../shared/utils";
import { Grid, Typography } from "@material-ui/core";
import { EmployeeField } from "../../components/EmployeeField";
import { DescriptionOutlined } from "@material-ui/icons";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { StatCard } from "../../components/StatCard";
import { useListQueryParams } from "../../shared/hooks/useListQueryParams";
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import AvTimerIcon from '@material-ui/icons/AvTimer';


export const PresenceListHeader = (props: { showDays: any, setShowDays: any }) => {
  const resource = useResourceContext();
  const query = useListQueryParams();
  const [stats, setStats] = useState({
    sum_presences: 0,
    sum_minutes: 0,
    sel_sum_presences: 0,
    sel_sum_minutes: 0,
  });

  const { total, data, ids, selectedIds } = useListContext();

  useEffect(() => {
    let temp_stats = {
      sum_presences: 0,
      sum_minutes: 0,
      sel_sum_presences: 0,
      sel_sum_minutes: 0,
    };
    for (let k of ids) {
      const element = data[k];
      if (element["date_out"]) {
        const date_out = new Date(element["date_out"]);
        const date_in = new Date(element["date_in"]);
        const diff = (date_out.getTime() - date_in.getTime()) / 1000 / 60;
        temp_stats = {
          sum_presences: temp_stats.sum_presences + 1,
          sum_minutes: temp_stats.sum_minutes + diff,
          sel_sum_presences: temp_stats.sel_sum_presences,
          sel_sum_minutes: temp_stats.sel_sum_minutes,
        };
      }
    }
    for (let k of selectedIds) {
      const element = data[k];
      if (element["date_out"]) {
        const date_out = new Date(element["date_out"]);
        const date_in = new Date(element["date_in"]);
        const diff = (date_out.getTime() - date_in.getTime()) / 1000 / 60;
        temp_stats = {
          sum_presences: temp_stats.sum_presences,
          sum_minutes: temp_stats.sum_minutes,
          sel_sum_presences: temp_stats.sel_sum_presences + 1,
          sel_sum_minutes: temp_stats.sel_sum_minutes + diff,
        };
      }
    }
    setStats(temp_stats);

  }
    , [data, selectedIds]);
  return (
    <Grid container>
      <Grid item xs={2}>
        <Button onClick={() => props.setShowDays(!props.showDays)}>
          <Typography>
            {
              props.showDays === true ? "Nascondi giorni" : "Mostra giorni"
            }
          </Typography>
        </Button>
      </Grid>
      <Grid item container xs={5}>
        <Grid item xs={3}>
          <StatCard
            label={`Totali`}
            icon={<></>}
            subtitle="Totali pagina"
            backgroundColor="lightblue"
          />
        </Grid>
        <Grid item xs={3}>
          <StatCard
            label={`N°: ${stats.sum_presences}`}
            icon={<ConfirmationNumberIcon />}
            subtitle="Numero turni"
            backgroundColor="lightblue"
          />
        </Grid>
        <Grid item xs={3}>
          <StatCard
            label={`${Math.floor(stats.sum_minutes / 60)}:${("00" + Math.floor(stats.sum_minutes % 60)).slice(-2)}`}
            icon={<AlarmAddIcon />}
            subtitle="Totale ore turni"
            backgroundColor="lightblue"
          />
        </Grid>
        <Grid item xs={3}>
          <StatCard
            label={`${Math.floor(stats.sum_minutes / stats.sum_presences / 60)}:${("00" + Math.floor(stats.sum_minutes / stats.sum_presences % 60)).slice(-2)}`}
            icon={<AvTimerIcon />}
            subtitle="Media ore turni"
            backgroundColor="lightblue"
          />
        </Grid>
      </Grid>
      <Grid item container xs={5}>
        <Grid item xs={3}>
          <StatCard
            label={`Selezionati`}
            icon={<></>}
            subtitle="Totali selezionati"
            backgroundColor="lightcoral"
          />
        </Grid>
        <Grid item xs={3}>
          <StatCard
            label={`N°: ${stats.sel_sum_presences}`}
            icon={<ConfirmationNumberIcon />}
            subtitle="Numero turni selezionati"
            backgroundColor="lightcoral"
          />
        </Grid>
        <Grid item xs={3}>
          <StatCard
            label={`${Math.floor(stats.sel_sum_minutes / 60)}:${("00" + Math.floor(stats.sel_sum_minutes % 60)).slice(-2)}`}
            icon={<AlarmAddIcon />}
            subtitle="Totale ore turni selezionati"
            backgroundColor="lightcoral"
          />
        </Grid>
        <Grid item xs={3}>
          <StatCard
            label={stats.sel_sum_minutes && stats.sel_sum_presences ? `${Math.floor(stats.sel_sum_minutes / stats.sel_sum_presences / 60)}:${("00" + Math.floor(stats.sel_sum_minutes / stats.sel_sum_presences % 60)).slice(-2)}` : "0:00"}
            icon={<AvTimerIcon />}
            subtitle="Media ore turni selezionati"
            backgroundColor="lightcoral"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};


export const PresenceList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");

  const [showDays, setShowDays] = useState(false);
  const [stats, setStats] = useState({
    sum_presences: 0,
    sum_minutes: 0,
  });


  const filters = options
    ? [
      <NumberInput
        {...initField("id", options)}
        label="ID"
      />,
      <TextInput
        {...initField("description", options)}
        label="Descrizione"
      />,
      <ReferenceInput
        source="employee"
        label="Operatore"
        reference="autocomplete/employee"
        isRequired={false}
        required={false}
        requiredInput={false}
        alwaysOn
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <TextInput  {...initField("info", options)} />,
      <DateTimeInput
        {...initField("date_in_range_after", options)}
        label="Data login dal"
        alwaysOn
      />,
      <DateTimeInput
        {...initField("date_in_range_before", options)}
        label="Data login al"
        alwaysOn
      />,
      <ReferenceInput
        {...initInput("department", options)}
        reference="autocomplete/department"
        source="department"
        label="Reparto"
        alwaysOn
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <SelectInput
        {...initInput("logged_type", options, "array")}
        optionText="display_name"
        optionValue="value"
        label="Loggati" choices={
          [
            { value: "in_out", display_name: "Entrati e Usciti" },
            { value: "only_in", display_name: "No Usciti" },
          ]
        } />,


    ]
    : [];

  function getDateColor(in_out: string, info: string) {
    if (info === null) {
      return "green";
    }
    if (in_out === "in") {
      if (info.includes("MAN_IN")) {
        return "yellow";
      }
      if (info.includes("AUT_IN")) {
        return "grey";
      }
      return "green";
    }
    else {
      if (info.includes("MAN_OUT")) {
        return "yellow";
      }
      if (info.includes("AUT_OUT")) {
        return "grey";
      }
      return "green";
    }
  }


  return options ? (
    <CustomList {...getDefaultListProps(props)}
      filters={filters}
      sort={{ field: "id", order: "DESC" }}
      topElement={
        <PresenceListHeader showDays={showDays} setShowDays={setShowDays} />
      }
    >
      <Datagrid>
        <IdentifierField {...initField("id", options)} />
        <EmployeeField {...initField("employee_object", options)} label="Operatore" />
        <TextField {...initField("departments", options)} label="Reparti" />

        {
          showDays === true ?
            <DateField {...initField("date_in", options)} label="GIORNO"
              options={{
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                weekday: 'long',
              }} /> : null
        }

        <FunctionField
          label=""
          render={(tableRecord: Record | undefined) => {
            if (tableRecord) {
              const date_in = tableRecord["date_in"];
              if (date_in) {
                return (
                  <div style={{
                    borderRadius: "50%",
                    border: "0.5px solid black",
                    width: "20px", height: "20px",
                    backgroundColor: getDateColor("in", tableRecord["info"]),
                    opacity: 0.8
                  }}></div>);
              }
            }
            return null;
          }}
          sortable={false}
        />

        <FunctionField
          label="ENTRATA"
          render={(tableRecord: Record | undefined) => {
            if (tableRecord) {
              const date_in = tableRecord["date_in"];
              if (date_in) {
                return (
                  <Typography variant="body2" style={{ color: "black" }}>
                    {format(new Date(date_in), showDays === true ? "HH:mm" : "dd/MM/yyyy HH:mm")}
                  </Typography>)
              }
            }
            return null;
          }}
          sortable={false}
        />




        <FunctionField
          label="USCITA"
          render={(tableRecord: Record | undefined) => {
            if (tableRecord) {
              const date_out = tableRecord["date_out"];
              if (date_out) {

                const date_out_day = new Date(format(new Date(tableRecord["date_out"]), "yyyy-MM-dd"))
                const date_in_day = new Date(format(new Date(tableRecord["date_in"]), "yyyy-MM-dd"))

                const diff_day = (date_out_day.getTime() - date_in_day.getTime()) / (1000 * 60 * 60 * 24)

                /**/
                return (
                  <Typography variant="body2" style={{ color: "black" }} >
                    {
                      format(new Date(date_out), showDays === true ? "HH:mm" : "dd/MM/yyyy HH:mm")
                    }
                    {
                      showDays === true && (Math.floor(Math.abs(diff_day)) == 0 ? "" : " + " + Math.abs(diff_day) + " gg")
                    }
                  </Typography>)
              }
            }
            return null;
          }}
          sortable={false}
        />
        <FunctionField
          label=""
          render={(tableRecord: Record | undefined) => {
            if (tableRecord) {
              const date_out = tableRecord["date_out"];
              if (date_out) {
                return (
                  <div style={{
                    borderRadius: "50%",
                    border: "0.5px solid black",
                    width: "20px", height: "20px",
                    backgroundColor: getDateColor("out", tableRecord["info"]),
                    opacity: 0.8
                  }}></div>);
              }
            }
            return null;
          }}
          sortable={false}
        />



        <TextField {...initField("hour", options)} label="Ore" />

        <FunctionField
          label="GPS IN"
          render={(tableRecord: Record | undefined) => {
            if (tableRecord) {
              const gps_in = tableRecord["gps_in"];
              if (gps_in) {
                const [lat, lng] = gps_in.split(";");
                return (
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Vedi
                  </a>
                );
              }
            }
            return null;
          }}
          sortable={false}
        />
        <FunctionField
          label="GPS OUT"
          render={(tableRecord: Record | undefined) => {
            if (tableRecord) {
              const gps_out = tableRecord["gps_out"];
              if (gps_out) {
                const [lat, lng] = gps_out.split(";");
                return (
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Vedi
                  </a>
                );
              }
            }
            return null;
          }}
          sortable={false}
        />

        <TextField {...initField("qr_in", options)} label="Qrcode IN" />
        <TextField {...initField("qr_out", options)} label="Qrcode OUT" />

        <TextField {...initField("notes_in", options)} label="Note IN" />
        <TextField {...initField("notes_out", options)} label="Note OUT" />

        <TextField  {...initField("info_complete", options,)} />
        <TextField {...initField("description", options)} label="Descrizione" />
      </Datagrid>
    </CustomList >
  ) : null;
};
