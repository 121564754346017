import { Card } from "@material-ui/core";
import { CreateProps } from "react-admin";
import { CustomCreate } from "../../components/CustomCreate";
import { WorkPermitForm } from "./WorkPermitForm";

export const WorkPermitCreate = (props: CreateProps) => {
  return (
    <CustomCreate {...props} component="div" >
      <WorkPermitForm />
    </CustomCreate>
  );
};
