import axios, { AxiosRequestConfig } from "axios";
import { stringify } from "querystring";
import { Identifier, DataProvider } from "ra-core";
import {
  FilterPayload,
  GetOneParams,
  PaginationPayload,
  SortPayload,
} from "react-admin";
import { getAccessToken, getRefreshToken, getUser, logout } from "./authProvider";
import { url } from "./constants";
import { Log } from "./shared/types";
import { convertFileToBase64 } from "./shared/utils";
import { access } from "fs";

export const getPaginationQuery = (pagination: PaginationPayload) => {
  return {
    page: pagination.page,
    page_size: pagination.perPage,
  };
};

export const getFilterQuery = (filter: FilterPayload) => {
  const { q: search, ...otherSearchParams } = filter;
  return {
    ...otherSearchParams,
    search,
  };
};

export const getOrderingQuery = (sort: SortPayload) => {
  const { field, order } = sort;
  return {
    ordering: `${order === "ASC" ? "" : "-"}${field}`,
  };
};
const app = axios.create();
app.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();
    config.headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
app.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      originalRequest.url === `${url}/token/refresh/`
    ) {
      //return Promise.reject(error);
      logout()
      window.location.href = "/login"
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      return app({
        url: `${url}/token/refresh/`,
        method: "POST",
        data: {
          refresh: getRefreshToken(),
        },
      }).then((res) => {
        if (res.status === 200) {
          const user = getUser();
          user.access = res.data.access;
          localStorage.setItem("user", JSON.stringify(user));
          return app(originalRequest);
        }
        else {
          logout()
          window.location.href = "/login"
        }
      });
    }
    return Promise.reject(error);
  }
);
export const endpoint = url;
export async function clientNoJson(
  endpoint: string,
  config: AxiosRequestConfig
) {
  return app({
    ...config,
    url: endpoint,
  });
}

export async function client(resource: string, config: AxiosRequestConfig, noslash = false) {
  return app({
    ...config,
    url: `${endpoint}/${resource}` + (noslash ? "" : "/"),
  }).then(async (response) => {
    return await response.data;
  });
}

export const refreshPresenceInfoView = async () => {
  client(`refresh_presence`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "Content-Type": "application/json",
    }
  }).then((response) => {
    const user = getUser();
    user.current_presence = response.current_presence;
    user.last_presence = response.last_presence;
    localStorage.setItem("user", JSON.stringify(user));
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  })
    .catch((error) => {
    })
    .finally(() => {
    });
};

export const getRealResource = (resource: string) => {
  const customResources: Record<string, string> = {
    "verified-charges": "charges",
    "verified-charges-summary": "charges-summary",
    "load-routes": "routes",
    "charges-a": "charges",
    "verified-charges-summary-a": "charges-summary-a",
    "verified-charges-a": "charges",
    "invoices-a": "invoices",
    "invoices-time-table-a": "invoices-time-table",
    "ribas": "invoices",
    "presences_only_in": "presences",
    "workpermit_totals": "workpermits",
  };
  if (resource in customResources) return customResources[resource];
  return resource;
};

type ExtendedDataProvider = DataProvider & {
  getHistory: (
    resource: string,
    params: GetOneParams
  ) => Promise<{ data: Log[] }>;
  uploadFiles: (resource: string, params: GetOneParams) => Promise<any>;
  uploadXMLFiles: (resource: string, params: GetOneParams) => Promise<any>;
  getGroupedBy: (resource: string, params: any) => Promise<{ data: Log[] }>;
};
const dataprovider = (apiUrl: String): ExtendedDataProvider => {
  const getOneJson = (resource: String, id: Identifier) =>
    app(`${apiUrl}/${resource}/${id}/`).then((response) => response.data);

  return {
    getList: async (resource, params) => {
      const query = {
        ...getFilterQuery(params.filter),
        ...getPaginationQuery(params.pagination),
        ...getOrderingQuery(params.sort),
      };
      const url = `${apiUrl}/${getRealResource(resource)}/?${stringify(query)}`;

      const { data } = await app(url);
      const { results, count, ...rest } = data;
      return {
        data: results,
        total: count,
        rest: rest,
      };
    },

    getOne: async (resource, params) => {
      const data = await getOneJson(getRealResource(resource), params.id);
      return {
        data,
      };
    },

    getMany: (resource, params) => {
      return app(`${apiUrl}/${getRealResource(resource)}/get_many/`, {
        method: "POST",
        data: { ids: params.ids },
      }).then(({ data }) => ({ data }));
    },

    getManyReference: async (resource, params) => {
      const query = {
        ...getFilterQuery(params.filter),
        ...getPaginationQuery(params.pagination),
        ...getOrderingQuery(params.sort),
        [params.target]: params.id,
      };
      const url = `${apiUrl}/${getRealResource(resource)}/?${stringify(query)}`;

      const { data } = await app(url);
      return {
        data: data.results,
        total: data.count,
      };
    },

    update: async (resource, params) => {
      if ((resource === "notes" ||
        resource === "delivery_attachments" ||
        resource === "workpermitrules") &&
        "file" in params.data && params.data.file) {
        return convertFileToBase64(params.data.file).then(async (file) => {
          const { data } = await app(
            `${apiUrl}/${getRealResource(resource)}/${params.id}/`,
            {
              method: "PATCH",
              data: { ...params.data, file },
            }
          );
          return { data };
        });
      } else {
        const { data } = await app(
          `${apiUrl}/${getRealResource(resource)}/${params.id}/`,
          {
            method: "PATCH",
            data: params.data,
          }
        );
        return { data };
      }
    },

    updateMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          app(`${apiUrl}/${getRealResource(resource)}/${id}/`, {
            method: "PATCH",
            data: JSON.stringify(params.data),
          })
        )
      ).then((responses) => ({ data: responses.map(({ data }) => data.id) })),
    uploadFiles: async (resource, params: any) => {
      if (resource === "upload") {
        const formData = new FormData();
        params.files.forEach((file: any) =>
          formData.append("files", file.rawFile)
        );
        return app(`${apiUrl}/${getRealResource(resource)}/`, {
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
    },
    uploadXMLFiles: async (resource: string, params: any) => {
      if (resource === "xml_invoices_imports") {
        const formData = new FormData();
        params.files.forEach((file: any) =>
          formData.append("files", file.rawFile)
        );
        return app(`${apiUrl}/${getRealResource(resource)}/`, {
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
    },
    create: async (resource, params) => {
      if ((
        resource === "notes" ||
        resource === "customer_import_files" ||
        resource === "delivery_attachments" ||
        resource === "workpermitrules"
      ) &&
        "file" in params.data && params.data.file) {
        if (params.data.file.toString().includes("data:image")) {
          const { data } = await app(
            `${apiUrl}/${getRealResource(resource)}/`,
            {
              method: "POST",
              data: { ...params.data },
            }
          );
          return { data };
        }
        return convertFileToBase64(params.data.file).then(async (file) => {
          const { data } = await app(
            `${apiUrl}/${getRealResource(resource)}/`,
            {
              method: "POST",
              data: { ...params.data, file },
            }
          );
          return { data };
        });
      } else {
        return app(`${apiUrl}/${getRealResource(resource)}/`, {
          method: "POST",
          data: params.data,
        }).then(({ data }) => ({
          data,
        }));
      }
    },

    delete: (resource, params) =>
      app(`${apiUrl}/${getRealResource(resource)}/${params.id}/`, {
        method: "DELETE",
      }).then(() => ({ data: { ...params.previousData } } as any)),

    deleteMany: (resource, params) =>
      app(`${apiUrl}/${getRealResource(resource)}/bulk_delete/`, {
        method: "POST",
        data: { ids: params.ids },
      }).then(() => ({ data: params.ids })),

    getHistory: async (resource, params) => {
      const data = await app(
        `${apiUrl}/${getRealResource(resource)}/${params.id}/history/`
      ).then((response) => response.data);
      return { data: data };
    },
    getGroupedBy: async (resource, params) => {
      const data = await app(
        `${apiUrl}/${getRealResource(resource)}/group_by/?${stringify(params)}`
      ).then((response) => response.data);
      return { data: data };
    },
  };
};
export default dataprovider;
