import React from "react";
import greenIconImg from "../assets/marker-icon-green.png";
import redIconImg from "../assets/marker-icon-red.png";
import { relative } from "path";
export const NumberedIcon = ({
  sequences,
  starting,
  underline,
  has_notes,
}: {
  sequences: number[];
  starting: boolean;
  underline?: boolean;
  has_notes?: boolean;
}) => {
  return (
    <div className="sequence-icon" style={{
      borderBottom: underline ? "2px solid red" : "none",
    }}>
      {has_notes ? <div style={{
        borderRadius: "50%",
        height: "10px", width: "10px",
        backgroundColor: "red",
        position: "relative",
        top: "35px",
        left: "0px",
        zIndex: 100000,
      }}></div> : null
      }
      <div className="circles-sequence" >
        {sequences.map((sequence) => (
          <span key={sequence}>{sequence}</span>
        ))}
      </div>
      <img
        alt={starting ? "punto di partenza" : "punto di scarico"}
        src={starting ? redIconImg : greenIconImg}
      />
    </div>
  );
};
