import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import {
  ArrayField,
  AutocompleteInput,
  BooleanInput,
  Button,
  Datagrid,
  DateField,
  DateInput,
  FormWithRedirect,
  FunctionField,
  NumberField,
  ReferenceInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  useCreate,
  useListContext,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "react-admin";
import { FormSaveButton } from "../../components/FormSaveButton";
import { useOptions } from "../../shared/hooks/useOptions";
import { useToggler } from "../../shared/hooks/useToggler";
import { dateFormatter, generateError, initField, initInput } from "../../shared/utils";
import IconCancel from "@material-ui/icons/Cancel";
import UpdateIcon from "@material-ui/icons/Update";
import { DialogSelectInput } from "../../components/DialogSelectInput";
import { SubjectField } from "../../components/SubjectField";
import { useEffect, useState } from "react";
import { client } from "../../dataProvider";
import { de } from "date-fns/locale";

interface UpdateChecksBulkButtonProps { check: "check_1" | "check_2" | "check_3", label: string }

export const UpdateChecksBulkButton = (props: UpdateChecksBulkButtonProps) => {

  const { selectedIds, refetch } = useListContext();
  const { value, setFalse, setTrue } = useToggler();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    setLoading(true);

    client(`workpermits/update_checks`, {
      method: "POST",
      data: {
        ids: selectedIds,
        check: props.check,
        value: values["value"],
        refuse_reason: values["refuse_reason"]
      }
    }).then((response) => {
      notify("Permessi aggiornato");
    })
      .catch((error) => {
        generateError(error, notify);
      })
      .finally(() => {
        setLoading(false);
        setFalse()
      })

      ;
    refetch();

  };

  function checkToString(check: string) {
    {
      let label = props.check.toString();
      switch (props.check) {
        case "check_1":
          label = "Check Disponibilità ore";
          break;
        case "check_2":
          label = "Check Disponibilità turni";
          break;
        default:
          label = "Check Convalida";
          break;
      }
      return label.toString();
    }
  }

  return (
    <>
      <Button tabIndex={-1} label={checkToString(props.check)} onClick={() => setTrue()}>
        <UpdateIcon />
      </Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          resource="delivery-states"
          save={handleSubmit}
          initialValues={{}}
          render={({ handleSubmitWithRedirect, pristine, saving, form }) => (
            <>
              <DialogContent>

                <SelectInput
                  label={"Check"}
                  source="check"
                  required={true}
                  optionText="name"
                  optionValue="id"
                  choices={[
                    { id: "check_1", name: checkToString("check_1") },
                    { id: "check_2", name: checkToString("check_2") },
                    { id: "check_3", name: checkToString("check_3") },
                  ]}
                  defaultValue={props.check}
                  disabled={true}
                  fullWidth
                />

                <SelectInput
                  label={"Valore"}
                  source="value"
                  required={true}
                  optionText="name"
                  optionValue="id"
                  choices={[
                    { id: "W", name: "In Attesa" },
                    { id: "A", name: "Accettato" },
                    { id: "R", name: "Rifiutato" },
                  ]}
                  defaultValue={"A"}
                  fullWidth
                />

                <ReferenceInput
                  resource="refuse_reason"
                  label={"Motivo rifiuto"}
                  reference="autocomplete/refusereason"
                  source="refuse_reason"
                  fullWidth
                  allowEmpty
                >
                  <AutocompleteInput optionText="label" />
                </ReferenceInput>

              </DialogContent>

              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={pristine}
                  label="Aggiorna"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};
