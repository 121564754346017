import React from "react";
import { AutocompleteArrayInput, AutocompleteInput, BooleanInput, DateInput, DateTimeInput, FileField, FileInput, FormDataConsumer, FormWithRedirect, GET_LIST, NumberInput, ReferenceArrayInput, ReferenceInput, SelectInput, SimpleForm, TextInput, useFormContext } from "react-admin";
import { CustomToolbar } from "../../components/CustomToolbar";
import { FormProps } from "../../shared/types";
import { initField, initInput } from "../../shared/utils";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useOptions } from "../../shared/hooks/useOptions";
import { getUser } from "../../authProvider";
import { CustomFileField } from "../../components/CustomFIleField";

const useStyles = makeStyles({
  root: {
    // set margin to nested grid
    "& .MuiGrid-root": {
      padding: "4px",
    },
  }
});

export const WorkPermitRuleForm = (props: FormProps) => {
  const user = getUser();
  const classes = useStyles();


  return (
    user ?
      <FormWithRedirect
        {...props}
        render={(formProps: any) => (
          <form>
            <Grid container classes={classes} xs={12} style={{ padding: "1em" }} >
              <Grid item xs={12} md={6}>
                <ReferenceInput
                  {...initInput("employee", props.fieldOptions, props.canEdit)}
                  reference="autocomplete/employee"
                  source="employee"
                  defaultValue={user.employee}
                  disabled={
                    user.permissions.includes("base.change_workpermit_c1") ||
                      user.permissions.includes("base.change_workpermit_c2") ||
                      user.permissions.includes("base.change_workpermit_c3") ? false : true
                  }
                >
                  <AutocompleteInput optionText="label" />
                </ReferenceInput>
              </Grid>

              <Grid item xs={12} md={6}>
                <ReferenceInput
                  {...initInput("work_permit_type", props.fieldOptions, props.canEdit)}
                  reference="autocomplete/workpermittype"
                  source="work_permit_type"
                >
                  <AutocompleteInput optionText="label" />
                </ReferenceInput>
              </Grid>

              <Grid item xs={12} md={6}>
                <DateInput {...initInput("start_date", props.fieldOptions, props.canEdit)} />
              </Grid>

              <Grid item xs={12} md={6}>
                <DateInput {...initInput("end_date", props.fieldOptions, props.canEdit)} />
              </Grid>


              <Grid container item xs={12} md={6}>

                <Grid item xs={6} md={6}>
                  <SelectInput
                    {...initInput("rule_type", props.fieldOptions, props.canEdit, "array")}
                    choices={props.fieldOptions.rule_type.choices} />
                </Grid>

                <Grid item xs={6} md={6}>
                  <BooleanInput resource="create_permitt" source="create_permitt" label="Crea permesso" />
                </Grid>

                <Grid item xs={5} md={3}>
                  {
                    formProps ? <NumberInput
                      source="temp_hours"
                      required={false}
                      resource="temp_hours"
                      step={0.25}
                      label={"Ore/giorno"}
                      onChange={(e) => {
                        formProps.form.change("hours", e.target.value * formProps.form.getFieldState("temp_day").value)
                      }
                      }
                    /> : null
                  }


                </Grid>

                <Grid item xs={1} md={1}>
                  <Typography variant="h3" align="center">X</Typography>
                </Grid>
                <Grid item xs={5} md={3}>
                  {
                    formProps ?
                      <NumberInput
                        source="temp_day"
                        required={false}
                        resource="temp_hours"
                        step={1}
                        label={"Giorni"}
                        onChange={(e) => {
                          formProps.form.change("hours", e.target.value * formProps.form.getFieldState("temp_hours").value)
                        }
                        }
                      /> : null
                  }

                </Grid>

                <Grid item xs={1} md={1}>
                  <Typography variant="h3" align="center">=</Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <NumberInput {...initInput("hours", props.fieldOptions, props.canEdit)}
                    step={0.25}
                  />

                </Grid>


                <TextInput {...initInput("description", props.fieldOptions, props.canEdit)} />

                <TextInput {...initInput("notes", props.fieldOptions, props.canEdit)} />
              </Grid>


              <Grid item container spacing={2} xs={6} alignItems="center">
                <Grid item>
                  <Typography>File auttale:</Typography>
                </Grid>
                <Grid item>
                  <CustomFileField
                    {...initField("file_info", props.fieldOptions)}
                    title="name"
                    src="url"
                  />
                </Grid>
                <Grid item>
                  <FileInput
                    {...initInput("file", props.fieldOptions, props.canEdit)}
                  >
                    <FileField source="src" title="title" />
                  </FileInput>
                </Grid>
              </Grid>


            </Grid>
            <CustomToolbar
              canDelete={getUser().is_admin === true ? props.canDelete : false}
              canEdit={props.canEdit}
              resource={props.resource}
              pristine={formProps.pristine}
              record={formProps.record}
              mutationMode={props.mutationMode}
              invalid={formProps.invalid}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              basePath={props.basePath}
            />
          </form>
        )} /> : null
  );
};
