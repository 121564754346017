import { AutocompleteArrayInput, AutocompleteInput, BulkDeleteButton, Button, Datagrid, DateField, DateInput, FormWithRedirect, FunctionField, ListProps, NumberField, NumberInput, Record, ReferenceArrayInput, ReferenceInput, SelectField, SelectInput, TextField, TextInput, useListContext, useNotify, useResourceContext } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { IdentifierField } from "../../components/IdentifierField";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField, initInput } from "../../shared/utils";
import { Dialog, DialogActions, DialogContent, Grid, Typography } from "@material-ui/core";
import { EmployeeField } from "../../components/EmployeeField";
import { getUser } from "../../authProvider";
import { useToggler } from "../../shared/hooks/useToggler";
import { useOptions } from "../../shared/hooks/useOptions";
import { FormSaveButton } from "../../components/FormSaveButton";
import { useEffect, useState } from "react";
import IconCancel from "@material-ui/icons/Cancel";
import UpdateIcon from "@material-ui/icons/Update";
import { client } from "../../dataProvider";
import { WorkPermitTypeField } from "../../components/WorkPermitTypeField";
import { tr } from "date-fns/locale";
import { CustomFileField } from "../../components/CustomFIleField";
import { useListQueryParams } from "../../shared/hooks/useListQueryParams";
import { StatCard } from "../../components/StatCard";


const GenerateWorkPermitRule = (props: any) => {
  const user = getUser();
  const { value, setFalse, setTrue } = useToggler();
  const [loading, setLoading] = useState(false);
  const { data: options } = useOptions("workpermitrules", "GET");
  const notify = useNotify();

  const handleSubmit = (values: any) => {
    console.log(values);
    client("workpermitrules/generate_annual_workpermit_rule", {
      method: "POST",
      data: JSON.stringify(values),
    })
      .then((response) => {
        setLoading(false);
        setFalse();
        notify("Regole permessi creati con successo", "success");
        setTimeout(() => {
          window.location.reload();
        },
          2000);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setFalse();
        notify("Errore nel creare le regole permessi", "error");
      });
  };

  return options && user.is_admin === true ?
    (<>
      <Button tabIndex={-1} label="Crea regole permessi" onClick={() => setTrue()}>
        <UpdateIcon />
      </Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          resource="workpermitrules"
          save={handleSubmit}
          initialValues={{}}
          render={({ handleSubmitWithRedirect, pristine, saving, form }) => (
            <>
              <DialogContent>
                <Grid container spacing={2}>

                  <Grid item xs={6}>
                    <DateInput {...initInput("start_date", options, true)} />
                  </Grid>
                  <Grid item xs={6}>
                    <DateInput {...initInput("end_date", options, true)} />
                  </Grid>

                  <Grid item xs={12}>
                    <ReferenceInput
                      {...initInput("work_permit_type", options, true)}
                      reference="autocomplete/workpermittype"
                      source="work_permit_type"
                    >
                      <AutocompleteInput optionText="label" />
                    </ReferenceInput>
                  </Grid>



                  <Grid item xs={6}>
                    <SelectInput
                      {...initInput("rule_type", options, true, "array")}
                      choices={options.rule_type.choices} />
                  </Grid>


                  <Grid item xs={6}>
                    <NumberInput {...initInput("hours", options, true)}
                      step={0.25}
                    />
                  </Grid>


                  <Grid item xs={12} >
                    <TextInput {...initInput("description", options, true)} />

                  </Grid>

                  <Grid item xs={12} >
                    <TextInput {...initInput("notes", options, true)} />

                  </Grid>

                </Grid>


              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={pristine}
                  label="Crea"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>

    </>) :
    <></>

}

const GenerateWorkPermitRuleMonthly = (props: any) => {
  const user = getUser();
  const { value, setFalse, setTrue } = useToggler();
  const [loading, setLoading] = useState(false);
  const { data: options } = useOptions("workpermitrules", "GET");
  const notify = useNotify();

  const handleSubmit = (values: any) => {
    console.log(values);
    client("workpermitrules/generate_monthly_workpermit_rule", {
      method: "POST",
      data: JSON.stringify(values),
    })
      .then((response) => {
        setLoading(false);
        setFalse();
        notify("Regole permessi creati con successo", "success");
        setTimeout(() => {
          window.location.reload();
        },
          2000);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setFalse();
        notify("Errore nel creare le regole permessi", "error");
      });
  };

  return options && user.is_admin === true ?
    (<>
      <Button tabIndex={-1} label="Crea regole permessi mensili" onClick={() => setTrue()}>
        <UpdateIcon />
      </Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          resource="workpermitrules"
          save={handleSubmit}
          initialValues={{}}
          render={({ handleSubmitWithRedirect, pristine, saving, form }) => (
            <>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ReferenceInput
                      {...initInput("employee", options, true)}
                      reference="autocomplete/employee"
                      source="employee"
                      isRequired={true}
                    >
                      <AutocompleteInput optionText="label" />
                    </ReferenceInput>
                  </Grid>

                  <Grid item xs={6}>
                    <DateInput {...initInput("start_date", options, true)} />
                  </Grid>
                  <Grid item xs={6}>
                    <DateInput {...initInput("end_date", options, true)} />
                  </Grid>

                  <Grid item xs={12}>
                    <ReferenceInput
                      {...initInput("work_permit_type", options, true)}
                      reference="autocomplete/workpermittype"
                      source="work_permit_type"
                    >
                      <AutocompleteInput optionText="label" />
                    </ReferenceInput>
                  </Grid>



                  <Grid item xs={6}>
                    <SelectInput
                      {...initInput("rule_type", options, false, "array")}
                      choices={options.rule_type.choices}
                      defaultValue={"M"}
                    />
                  </Grid>


                  <Grid item xs={6}>
                    <NumberInput {...initInput("hours", options, true)}
                      step={0.25}
                    />
                  </Grid>


                  <Grid item xs={12} >
                    <TextInput {...initInput("description", options, true)} />

                  </Grid>

                  <Grid item xs={12} >
                    <TextInput {...initInput("notes", options, true)} />

                  </Grid>

                </Grid>


              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={pristine}
                  label="Crea"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>

    </>) :
    <></>

}


const TopButton = (props: any) => {
  const user = getUser();

  return (
    user.is_admin === true ?
      <Grid container spacing={2}>
        <Grid item>
          <GenerateWorkPermitRule />
          <GenerateWorkPermitRuleMonthly />
        </Grid>
      </Grid> :
      <></>
  )
}



export const WorkPermitTuleListHeader = (props: any) => {
  const resource = useResourceContext();
  const query = useListQueryParams();
  const [stats, setStats] = useState({
    hours: 0,
    hours_used: 0,
  });

  const { total, data, ids, selectedIds } = useListContext();

  useEffect(() => {
    let temp_stats = {
      hours: 0,
      hours_used: 0,
    };
    for (let k of selectedIds) {
      const element = data[k];
      temp_stats = {
        hours: temp_stats.hours + element.hours,
        hours_used: temp_stats.hours_used + element.hours_used,
      };
    }
    setStats(temp_stats);

  }
    , [data, selectedIds]);
  return (
    <Grid container>
      <Grid item container xs={12}>
        <TopButton />
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={4}>
          <StatCard
            label={`Giorni totali: ${Math.round(stats.hours / 8 * 100) / 100}`}
            icon={<></>}
            subtitle=""
            backgroundColor="lightblue"
          />
        </Grid>
        <Grid item xs={4}>
          <StatCard
            label={`Giorni usati: ${Math.round(stats.hours_used / 8 * 100) / 100}`}
            icon={<></>}
            subtitle=""
            backgroundColor="lightblue"
          />
        </Grid>
        <Grid item xs={4}>
          <StatCard
            label={`Giorni residui: ${Math.round((stats.hours - stats.hours_used) / 8 * 100) / 100}`}
            icon={<></>}
            subtitle=""
            backgroundColor="lightblue"
          />
        </Grid>

      </Grid>
    </Grid>
  );
};








const BulkActionButtons = (props: any) => {
  const user = getUser();

  return (<>
    {user.is_admin === true ? <BulkDeleteButton {...props} /> : null}
  </>
  )
};




export const WorkPermitRuleList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const user = getUser();
  const red = "#ff0000";
  const green = "#00ff00";
  const gray = "#808080";


  const filters = options
    ? [
      <ReferenceArrayInput
        reference="autocomplete/employee"
        source={"employee"}
        label="Operatori"
        sort={{ "field": "label", "order": "ASC" }}
        className="filter-field-small"
        alwaysOn
      >
        <AutocompleteArrayInput optionText="label" fullWidth />
      </ReferenceArrayInput>,
      <ReferenceArrayInput
        reference="autocomplete/workpermittype"
        source={"work_permit_type"}
        label="Tipi permessi"
        sort={{ "field": "label", "order": "ASC" }}
        className="filter-field-small"
        alwaysOn
      >
        <AutocompleteArrayInput optionText="label" fullWidth />
      </ReferenceArrayInput>,
      <DateInput {...initField("start_date_after", options)} label="Data inizio dal" alwaysOn />,
      <DateInput {...initField("start_date_before", options)} label="Data inizio al" />,
      <DateInput {...initField("end_date_after", options)} label="Data fine dal" />,
      <DateInput {...initField("end_date_before", options)} label="Data fine al" alwaysOn />,

    ]
    : [];

  return options ? (
    <CustomList {...getDefaultListProps(props)}
      filters={filters}
      bulkActionButtons={<BulkActionButtons />}
      topElement={<WorkPermitTuleListHeader />}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <IdentifierField {...initField("id", options)} />
        <TextField {...initField("description", options)} label={"Info"} />
        <EmployeeField {...initField("employee_object", options)} label="Operatore" />
        <DateField {...initField("start_date", options)} />
        <DateField {...initField("end_date", options)} />
        <SelectField {...initField("rule_type", options, "array")} choices={options.rule_type.choices} />
        <TextField {...initField("work_permit_type_label", options)} label={"Categoria permesso"} />
        <NumberField {...initField("hours", options)} label={"Ore totali"} />
        <NumberField {...initField("hours_used", options)} label={"Ore usate"} />

        <FunctionField
          {...initField("file_info", options)}
          render={(tableRecord: any) => {
            if (tableRecord) {
              return (
                <CustomFileField
                  {...initField("file_info", options)}
                  title="short_name"
                  src="url"
                />

              );
            }
            return null;
          }}
          label={"Allegato"}
        />


      </Datagrid>
    </CustomList>
  ) : null;
};
