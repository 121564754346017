import {
  BulkUpdateButton,
  Button,
  ButtonProps,
  DateInput,
  FormWithRedirect,
  Identifier,
  SelectInput,
  useListContext,
  useNotify,
  useRefresh,
} from "react-admin";
import { ActionsToolbar } from "../../../components/ActionsToolbar";
import IconCancel from "@material-ui/icons/Cancel";

import BlockIcon from "@material-ui/icons/Block";
import ViewListIcon from '@material-ui/icons/ViewList';
import { client, clientNoJson } from "../../../dataProvider";
import { Dialog, DialogContent, DialogActions, Typography, CircularProgress } from "@material-ui/core";
import { FormSaveButton } from "../../../components/FormSaveButton";
import { useToggler } from "../../../shared/hooks/useToggler";
import { useState } from "react";
import { TariffType } from "../../../shared/types";
import { get } from "lodash";
import { getUser } from "../../../authProvider";
import { getFileNameFromContentDisposition } from "../../../shared/utils";

const SetReconciliationDateButton = ({ charges }: { charges: Identifier[] }) => {
  const { value, setFalse, setTrue } = useToggler();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = (values: any) => {
    setLoading(true);
    client("charges/set_reconciliation_date", {
      method: "POST",
      data: values,
    })
      .then(() => {
        notify("Fatto!", "success");
        refresh();
      })
      .catch((response) =>
        notify(
          "Errore! Controllare se dati gia riconciliati o errati!",
          "error"
        )
      )
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        label="Riconcilia"
        onClick={() => setTrue()}
      ></Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          save={handleSubmit}
          initialValues={{
            "ids": charges
          }}
          render={({ handleSubmitWithRedirect, pristine, saving, form }) => (
            <>
              <DialogContent>
                <DateInput
                  fullWidth
                  source="reconciliation_date"
                  label="Data riconciliazione"
                  required
                />

              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={pristine}
                  label="Riconcilia"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

const UnsetReconciliationDateButton = ({ charges }: { charges: Identifier[] }) => {
  const { value, setFalse, setTrue } = useToggler();
  const user = getUser();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = (values: any) => {
    setLoading(true);
    client("charges/unset_reconciliation_date", {
      method: "POST",
      data: values,
    })
      .then(() => {
        notify("Fatto!", "success");
        refresh();
      })
      .catch((response) =>
        notify(
          "Errore!",
          "error"
        )
      )
      .finally(() => setLoading(false));
  };

  return user.is_admin ? (
    <>
      <Button
        size="small"
        color="primary"
        label="Restetta data riconciliazione"
        onClick={() => setTrue()}
      ></Button>
      <Dialog open={value} maxWidth="sm" fullWidth>
        <FormWithRedirect
          save={handleSubmit}
          initialValues={{
            "ids": charges
          }}
          render={({ handleSubmitWithRedirect, pristine, saving, form }) => (
            <>
              <DialogContent>
                <Typography>
                  Sei sicuro di voler resettare la data di riconciliazione?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={setFalse}>
                  <IconCancel />
                </Button>
                <FormSaveButton
                  handleSubmit={() => handleSubmitWithRedirect!()}
                  submitting={loading}
                  pristine={false}
                  label="Resetta data"
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  ) : null;
};


export const ExportChargeBulkButton = ({ charges }: { charges: Identifier[] }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    setLoading(true);
    let res = null;
    try {
      res = await clientNoJson(`/api/charges/export_excel_selected/`, {
        method: "POST",
        data: {
          ids: charges
        },
        responseType: "arraybuffer",
      });
    } catch (err) {
      notify("ra.message.error", "error");
      setLoading(false);
      return;
    }
    setLoading(false);

    const data = res.data; // or res.blob() if using blob responses

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );

    // uses the download attribute on a temporary anchor to trigger the browser
    // download behavior. if you need wider compatibility, you can replace this
    // part with a library such as filesaver.js
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);

  };

  return (
    <Button
      disabled={loading}
      label="Expoort excel"
      title="Esport excel missioni selezionate"
      onClick={handleSubmit}
    >
      {loading ? <CircularProgress size={18} thickness={2} /> : <ViewListIcon />}
    </Button>
  );
};

interface PurchaseBulkActionsProps {
  selectedCharges: Identifier[];
  onUnselect: () => void;
}
export const PurchaseBulkActions = (props: PurchaseBulkActionsProps) => {
  const { selectedCharges, onUnselect } = props;

  return (
    <ActionsToolbar selected={selectedCharges} onUnselect={onUnselect}>
      <BulkUpdateButton
        selectedIds={selectedCharges}
        disabled={selectedCharges.length <= 0}
        data={{ verified: false }}
        mutationMode="pessimistic"
        label="Invalida"
        icon={<BlockIcon />}
      />
      <UnsetReconciliationDateButton charges={selectedCharges} />
      <SetReconciliationDateButton charges={selectedCharges} />
      <ExportChargeBulkButton charges={selectedCharges} />
    </ActionsToolbar>
  );
};
