import { Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import { getUser } from "../authProvider";
import { ChangePassword } from "../Users";
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { alpha, makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { TransitionProps } from '@material-ui/core/transitions';
import { client } from "../dataProvider";
import { useQuery } from "react-admin";
import { useState } from "react";


function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 20, height: 20 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 20, height: 20 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 20, height: 20 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps) {

  return (
    <div>
      <Collapse {...props} />
    </div>
  );
}


const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 20,
      paddingLeft: 40,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
    label: {
      fontSize: "1.2em",
      fontWeight: "bold"
    }
  }),
)((props: TreeItemProps) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles(
  createStyles({
    root: {
      height: "80%",
      flexGrow: 1,
      maxWidth: "80%",
    },
  }),
);



export const PermissionInfoPage = (props: any) => {

  const [usersGroupped, setUsersGroupped] = useState<any>([]);
  const [groupsUser, setGroupsUser] = useState<any>([]);

  client("get_users_groupped", { method: "GET" }).then((res: any) => {
    setUsersGroupped(res.groups)
    setGroupsUser(res.users)
  });


  const { permissions } = props;

  const classes = useStyles();


  function getPermissionFromCode(code: string) {
    let p = permission_info.find((permission) => permission.code === code);
    return p ? p.title : "Permesso non trovato";
  }

  function getTitoleFromGroupCode(code: string) {
    let g = groups_info.find((group) => group.code === code);
    return g ? g.title : "Gruppo non trovato";
  }

  function groupPermissionForMain() {
    let tempGrouppedPermisison: any = {};
    for (let permission of permission_info) {
      if (!tempGrouppedPermisison[permission.main]) {
        tempGrouppedPermisison[permission.main] = [];
      }
      tempGrouppedPermisison[permission.main].push(permission);
    }
    let grouppedPermisison = [];
    for (let group in tempGrouppedPermisison) {
      grouppedPermisison.push({ group: group, permissions: tempGrouppedPermisison[group] });
    }
    return grouppedPermisison;
  }

  function groupPermissionForMainSelected(permissions: [string]) {
    let tempGrouppedPermisison: any = {};
    for (let permission of permission_info) {
      if (permissions.includes(permission.code)) {
        if (!tempGrouppedPermisison[permission.main]) {
          tempGrouppedPermisison[permission.main] = [];
        }
        tempGrouppedPermisison[permission.main].push(permission);
      }
    }
    let grouppedPermisison = [];
    for (let group in tempGrouppedPermisison) {
      grouppedPermisison.push({ group: group, permissions: tempGrouppedPermisison[group] });
    }
    return grouppedPermisison;
  }


  const permission_info = [
    {
      main: "Anagrafiche",
      code: "employees.view_employee",
      title: "Visualizzare impiegati",
      message: ""
    },
    {
      main: "Anagrafiche",
      code: "employees.change_employee",
      title: "Modificare impiegati",
      message: ""
    },
    {
      main: "Anagrafiche",
      code: "fleet_management.view_vehicle",
      title: "Visualizzare veicoli",
      message: ""
    },
    {
      main: "Anagrafiche",
      code: "fleet_management.change_vehicle",
      title: "Modificare veicoli",
      message: ""
    },
    {
      main: "Anagrafiche",
      code: "registry.view_site",
      title: "Visualizzare sedi logistiche",
      message: ""
    },
    {
      main: "Anagrafiche",
      code: "registry.change_site",
      title: "Modificare sedi logistiche",
      message: ""
    },


    {
      main: "Bolle",
      code: "distribution.view_note",
      title: "Visualizzare bolle missioni",
      message: ""
    },
    {
      main: "Bolle",
      code: "distribution.change_note",
      title: "Modifcare e caricare bolle missioni",
      message: ""
    },


    {
      main: "Contrassegni",
      code: "cash_on_delivery.view_cachet",
      title: "Visualizzare i contrassegni",
      message: ""
    },
    {
      main: "Contrassegni",
      code: "cash_on_delivery.change_cachet",
      title: "Gestire e modifcare i contrassegni",
      message: ""
    },
    {
      main: "Contrassegni",
      code: "cash_on_delivery.display_cachet_cashed",
      title: "Visualizzare e modifcare i contrassegni incassati",
      message: ""
    },
    {
      main: "Contrassegni",
      code: "cash_on_delivery.display_cachet_to_cash_in",
      title: "Visualizzare e modifcare i contrassegni da incassare",
      message: ""
    },
    {
      main: "Contrassegni",
      code: "cash_on_delivery.view_remittance",
      title: "Visualizzare rimesse",
      message: ""
    },
    {
      main: "Contrassegni",
      code: "distribution.change_remittance",
      title: "Gestire e modifcare rimesse",
      message: ""
    },
    {
      main: "Contrassegni",
      code: "cash_on_delivery.view_cachetbox",
      title: "Visualizzare casse contrassegni",
      message: ""
    },
    {
      main: "Contrassegni",
      code: "cash_on_delivery.change_cachetbox",
      title: "Gestire e modifcare casse contrassegni",
      message: ""
    },
    {
      main: "Contrassegni",
      code: "cash_on_delivery.view_cachetpayment",
      title: "Visualizzare incassi contrassegni",
      message: ""
    },
    {
      main: "Contrassegni",
      code: "cash_on_delivery.change_cachetpayment",
      title: "Gestire e modifcare incassi contrassegni",
      message: ""
    },


    {
      main: "Fatturazione",
      code: "distribution.view_category",
      title: "Visualizzare categorie articoli",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "distribution.change_category",
      title: "Modifcare categorie articoli",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "distribution.view_item",
      title: "Visualizzare articoli",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "distribution.change_item",
      title: "Modifcare articoli",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "distribution.view_tariff",
      title: "Visualizzare tariffe",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "distribution.change_tariff",
      title: "Modifcare tariffe",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "distribution.view_subtariff",
      title: "Visualizzare sotto tariffe",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "accounting.view_charge",
      title: "Visualizzare addebiti/valida righe fattura",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "accounting.change_charge",
      title: "Modifcare addebiti/valida righe fattura",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "accounting.view_invoice",
      title: "Visualizzare fattura, riba e import xml",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "accounting.change_invoice",
      title: "Modifcare fattura, riba e import xml",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "accounting.view_invoiceline",
      title: "Visualizzare le righe fattura",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "accounting.change_invoiceline",
      title: "Modifcare fattura le righe fattura",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "accounting.view_invoicepaymentstatus",
      title: "Visualizzare scadenzario e stati pagamento fatture",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "accounting.change_invoicepaymentstatus",
      title: "Modifcare scadenzario e stati pagamento fatture",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "accounting.view_chartaccounts",
      title: "Visualizzare categorie piano dei conti",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "accounting.change_chartaccounts",
      title: "Modifcare categorie piano dei conti",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "accounting.view_chartaccountstag",
      title: "Visualizzare tags piano dei conti",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "accounting.change_chartaccountstag",
      title: "Modifcare tags piano dei conti",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "accounting.view_invoicechartaccounts",
      title: "Visualizzare info piano dei conti",
      message: ""
    },
    {
      main: "Fatturazione",
      code: "accounting.change_invoicechartaccounts",
      title: "Modifcare/associare info piano dei conti",
      message: ""
    },


    {
      main: "Generale",
      code: "general",
      title: "Login nel sistema e visualizzazione homepage con funzioni base",
      message: ""
    },
    {
      main: "Generale",
      code: "base.autocomplete_general_view",
      title: "Campi generici con autocompletamento (Es. province, regioni,..)",
      message: ""
    },
    {
      main: "Generale",
      code: "base.autocomplete_customer_view",
      title: "Campi con autocompletamento specifici per i clienti (Es. province, soggetti clienti, ecc",
      message: ""
    },


    {
      main: "Giri",
      code: "route_management.view_route",
      title: "Visualizzare giri e bacheca giri con i dettagli",
      message: ""
    },
    {
      main: "Giri",
      code: "route_management.change_route",
      title: "Modifcare giri e bacheca giri con i dettagli",
      message: ""
    },
    {
      main: "Giri",
      code: "route_management.delete_route",
      title: "Eliminare giri",
      message: ""
    },
    {
      main: "Giri",
      code: "distribution.view_route",
      title: "Visualizzare giri e bacheca giri con i dettagli (in relazione alle missioni, es pianificatore)",
      message: ""
    },
    {
      main: "Giri",
      code: "distribution.change_route",
      title: "Modifcare giri e bacheca giri con i dettagli (in relazione alle missioni, es pianificatore)",
      message: ""
    },
    {
      main: "Giri",
      code: "distribution.view_deliveryzone",
      title: "Visualizzare zone giri",
      message: ""
    },
    {
      main: "Giri",
      code: "distribution.change_deliveryzone",
      title: "Modificare zone giri",
      message: ""
    },
    {
      main: "Giri",
      code: "distribution.view_splitsegment",
      title: "Visualizzare split giri effettuati",
      message: ""
    },
    {
      main: "Giri",
      code: "distribution.change_splitsegment",
      title: "Modificare split giri effettuati",
      message: ""
    },

    {
      main: "Importazione file",
      code: "file_import.view_importedfile",
      title: "Visualizzare file importati e caricati (upload)",
      message: ""
    },
    {
      main: "Importazione file",
      code: "file_import.change_importedfile",
      title: "Modificare file importati e caricati (upload)",
      message: ""
    },
    {
      main: "Importazione file",
      code: "file_import.view_missingsubject",
      title: "Visualizzare i soggetti",
      message: ""
    },
    {
      main: "Importazione file",
      code: "file_import.change_missingsubject",
      title: "Risolvere i soggetti",
      message: ""
    },


    {
      main: "Log",
      code: "distribution.view_historicaldelivery",
      title: "Visualizzare log missioni",
      message: ""
    },
    {
      main: "Log",
      code: "registry.view_historicalsubject",
      title: "Visualizzare log soggetti",
      message: ""
    },
    {
      main: "Log",
      code: "cash_on_delivery.view_historicalcachet",
      title: "Visualizzare log contrassegni",
      message: ""
    },
    {
      main: "Log",
      code: "distribution.view_historicaldeliveryitem",
      title: "Visualizzare log servizi",
      message: ""
    },
    {
      main: "Log",
      code: "accounting.view_historicalcharge",
      title: "Visualizzare log addebiti",
      message: ""
    },

    {
      main: "Missioni",
      code: "distribution.view_delivery",
      title: "Visualizzare missioni",
      message: ""
    },
    {
      main: "Missioni",
      code: "distribution.change_delivery",
      title: "Modificare missioni",
      message: ""
    },
    {
      main: "Missioni",
      code: "distribution.view_segment",
      title: "Visualizzare tratte missioni",
      message: ""
    },
    {
      main: "Missioni",
      code: "distribution.change_segment",
      title: "Modificare tratte missioni",
      message: ""
    },
    {
      main: "Missioni",
      code: "distribution.view_deliverystatus",
      title: "Visualizzare stati missioni",
      message: ""
    },
    {
      main: "Missioni",
      code: "distribution.change_deliverystatus",
      title: "Modificare stati missioni",
      message: ""
    },
    {
      main: "Missioni",
      code: "distribution.view_reasonstatus",
      title: "Visualizzare ragioni stati missioni",
      message: ""
    },
    {
      main: "Missioni",
      code: "distribution.change_reasonstatus",
      title: "Modificare ragioni stati missioni",
      message: ""
    },
    {
      main: "Missioni",
      code: "base.view_delivery",
      title: "Visualizzare bacheca monitor giri",
      message: ""
    },
    {
      main: "Missioni",
      code: "distribution.view_deliveryitem",
      title: "Inseriere, modificare e visualizzazzare servizi missioni più info ricavo singola consegna",
      message: ""
    },
    {
      main: "Missioni",
      code: "distribution.view_deliveryattachment",
      title: "Inseriere, modificare e visualizzazzare allegati missioni",
      message: ""
    },
    {
      main: "Missioni",
      code: "distribution.view_deliverynoterow",
      title: "Visualizzazzare righe note missioni",
      message: ""
    },
    {
      main: "Missioni",
      code: "distribution.change_deliverynoterow",
      title: "Inseriere, modificare e visualizzazzare righe note missioni",
      message: ""
    },
    {
      main: "Missioni",
      code: "distribution.view_specificationnote",
      title: "Visualizzazzare note di capitolato",
      message: ""
    },
    {
      main: "Missioni",
      code: "distribution.change_specificationnote",
      title: "Inseriere, modificare e visualizzazzare note di capitolato",
      message: ""
    },
    {
      main: "Missioni",
      code: "distribution.view_deliverygroup",
      title: "Visualizzazzare ragguppamenti missioni",
      message: ""
    },
    {
      main: "Missioni",
      code: "distribution.change_deliverygroup",
      title: "Inseriere, modificare e visualizzazzare ragguppamenti missioni",
      message: ""
    },


    {
      main: "Portale clienti",
      code: "customers.view_customersubject",
      title: "Visualizzare soggetti portale clienti",
      message: ""
    },
    {
      main: "Portale clienti",
      code: "customers.change_customersubject",
      title: "Modifcare soggetti portale clienti",
      message: ""
    },
    {
      main: "Portale clienti",
      code: "customers.view_customerorder",
      title: "Visualizzare ordini portale clienti",
      message: ""
    },
    {
      main: "Portale clienti",
      code: "customers.change_customerorder",
      title: "Modifcare ordini portale clienti",
      message: ""
    },
    {
      main: "Portale clienti",
      code: "customers.view_customerorderitem",
      title: "Visualizzare servizi ordini portale clienti",
      message: ""
    },
    {
      main: "Portale clienti",
      code: "customers.change_customerorderitem",
      title: "Modifcare servizi ordini portale clienti",
      message: ""
    },
    {
      main: "Portale clienti",
      code: "customers.view_customerimportfile",
      title: "Visualizzare import file ordini portale clienti",
      message: ""
    },
    {
      main: "Portale clienti",
      code: "customers.change_customerimportfile",
      title: "Modifcare import file ordini portale clienti",
      message: ""
    },
    {
      main: "Portale clienti",
      code: "base.view_deliverytracking",
      title: "Visualizzare tracking portale clienti",
      message: ""
    },

    {
      main: "Portale vettori",
      code: "base.view_carrierdelivery",
      title: "Visualizzare ordini portale vettori",
      message: ""
    },
    {
      main: "Portale vettori",
      code: "base.change_carrierdelivery",
      title: "Modifcare ordini portale vettori",
      message: ""
    },
    {
      main: "Portale vettori",
      code: "carriers.view_carriercashbox",
      title: "Visualizzare e modifcare casse vettori",
      message: ""
    },


    {
      main: "Soggetti",
      code: "registry.view_subject",
      title: "Visualizzare soggetti",
      message: ""
    },
    {
      main: "Soggetti",
      code: "registry.change_subject",
      title: "Modificare soggetti",
      message: ""
    },


    {
      main: "Utenti",
      code: "auth.view_user",
      title: "Visualizzare utenti con acceso al sistema (clienti e vettori)",
      message: ""
    },
    {
      main: "Utenti",
      code: "auth.change_user",
      title: "Modifcare utenti con acceso al sistema (clienti e vettori)",
      message: ""
    },

    {
      main: "Permessi",
      code: "employees.change_workpermit_c1",
      title: "Modifcare permessi e regole permessi, più check convalida livello 1",
      message: ""
    },
    {
      main: "Permessi",
      code: "employees.change_workpermit_c2",
      title: "Modifcare permessi e regole permessi, più check convalida livello 2",
      message: ""
    },
    {
      main: "Permessi",
      code: "employees.change_workpermit_c3",
      title: "Modifcare permessi e regole permessi, più check convalida livello 3",
      message: ""
    },
    {
      main: "Permessi",
      code: "employees.view_workpermit",
      title: "Visualizzare permessi",
      message: ""
    },
    {
      main: "Permessi",
      code: "employees.change_workpermit",
      title: "Modifica permessi",
      message: ""
    },
    {
      main: "Permessi",
      code: "employees.view_workpermitrule",
      title: "Visualizza regole permessi",
      message: ""
    },
    {
      main: "Permessi",
      code: "employees.change_workpermitrule",
      title: "Modifica regole permessi",
      message: ""
    },
    {
      main: "Permessi",
      code: "employees.view_badge",
      title: "Visualizza badge",
      message: ""
    },
    {
      main: "Permessi",
      code: "employees.change_badge",
      title: "Modifica badge",
      message: ""
    },
    {
      main: "Permessi",
      code: "employees.view_presence",
      title: "Visualizza presenze",
      message: ""
    },
    {
      main: "Permessi",
      code: "employees.change_presence",
      title: "Modifica presenze",
      message: ""
    },
    {
      main: "Permessi",
      code: "employees.view_presenceqr",
      title: "Visualizza presenze qr",
      message: ""
    },
    {
      main: "Permessi",
      code: "employees.change_presenceqr",
      title: "Modifica presenze qr",
      message: ""
    },
    {
      main: "Permessi",
      code: "employees.view_presencezone",
      title: "Visualizza zone presenze",
      message: ""
    },
    {
      main: "Permessi",
      code: "employees.change_presencezone",
      title: "Modifica zone presenze",
      message: ""
    },

  ];

  const groups_info = [
    {
      code: "admin",
      title: "Admin",
      message: "",
      permissions: [
        "general"
      ]
    },

    {
      code: "driver",
      title: "Autista",
      message: "",
      permissions: [
        "general",
        "distribution.change_note",
      ]
    },

    {
      code: "client",
      title: "Cliente",
      message: "",
      permissions: [
        "general",
        "customers.add_customernote",
        "customers.change_customerimportfile",
        "customers.view_customersubject",
        "customers.add_customerorder",
        "customers.delete_customersubject",
        "base.autocomplete_customer_view",
        "customers.change_customerorder",
        "customers.delete_customerorder",
        "customers.view_customerorder",
        "customers.change_customersubject",
        "customers.view_customerimportfile",
        "customers.add_customersubject",
        "customers.add_customerorderitem",
        "customers.view_customerorderitem",
        "customers.change_customerorderitem",
        "distribution.view_subtariff",
        "distribution.change_subtariff",
      ]
    },
    {
      code: "client_tracking",
      title: "Cliente-Tracking",
      message: "",
      permissions: [
        "general",
        "base.view_deliverytracking",
      ]
    },

    {
      code: "cachet",
      title: "Contrassegni",
      message: "",
      permissions: [
        "general",
        "base.autocomplete_general_view",
        "cash_on_delivery.display_cachet_to_cash_in",
        "cash_on_delivery.display_cachet_cashed",
        "cash_on_delivery.view_cachet",
        "cash_on_delivery.change_cachet",
        "cash_on_delivery.view_cachetpayment",
        "cash_on_delivery.view_cachetbox",
      ]
    },

    {
      code: "dashboard",
      title: "Dashboard",
      message: "",
      permissions: [
        "general",
        "base.autocomplete_general_view",
        "route_management.view_route",
        "route_management.change_route",
        "distribution.view_driver",
        "distribution.view_route",
        "distribution.change_route",
        "distribution.view_vehicle",
      ]
    },

    {
      code: "desk",
      title: "Desk",
      message: "",
      permissions: [
        "general"
      ]
    },

    {
      code: "accountant",
      title: "Fatturazione",
      message: "",
      permissions: [
        "general",
      ]
    },

    {
      code: "warehouse",
      title: "Magazziniere",
      message: "",
      permissions: [
        "general",
        "base.autocomplete_general_view",
        "registry.view_subject",
        "distribution.view_delivery",
        "distribution.view_deliveryattachment",
        "distribution.view_deliverynoterow",
        "distribution.view_deliverystatus",
        "distribution.change_deliverystatus",
        "distribution.view_driver",
        "distribution.view_note",
        "distribution.change_note",
        "distribution.view_reasonstatus",
        "distribution.change_reasonstatus",
        "distribution.view_route",
        "distribution.view_segment",
        "distribution.change_segment",
        "distribution.view_vehicle",
        "route_management.view_route",
        "route_management.change_route",
        "distribution.change_deliverygroup",
        "distribution.view_deliverygroup",
      ]
    },
    {
      code: "warehouse_admin",
      title: "Magazziniere Admin",
      message: "",
      permissions: [
        "general",
        "base.autocomplete_general_view",
        "registry.view_subject",
        "registry.change_subject",
        "registry.view_referrer",
        "registry.change_referrer",
        "registry.view_site",
        "registry.view_specificationnote",
        "registry.change_specificationnote",
        "distribution.view_delivery",
        "distribution.view_deliveryattachment",
        "distribution.change_deliveryattachment",
        "distribution.view_deliverynoterow",
        "distribution.change_deliverynoterow",
        "distribution.view_deliverystatus",
        "distribution.change_deliverystatus",
        "distribution.delete_deliverystatus",
        "distribution.view_deliveryzone",
        "distribution.view_deliveryitem",
        "distribution.change_deliveryitem",
        "distribution.delete_deliveryitem",
        "distribution.view_driver",
        "employees.view_employee",
        "distribution.view_note",
        "distribution.change_note",
        "distribution.delete_note",
        "distribution.view_reasonstatus",
        "distribution.change_reasonstatus",
        "distribution.view_route",
        "distribution.change_route",
        "distribution.view_delivery",
        "distribution.change_delivery",
        "distribution.view_segment",
        "distribution.change_segment",
        "distribution.view_vehicle",
        "fleet_management.view_vehicle",
        "route_management.view_route",
        "route_management.change_route",
        "distribution.view_tariff",
        "distribution.view_subtariff",
        "file_import.view_missingsubject",
        "file_import.view_importedfile",
        "file_import.change_importedfile",
        "distribution.view_subtariff",
        "distribution.change_deliverygroup",
        "distribution.view_deliverygroup",
      ]
    },

    {
      code: "planner",
      title: "Pianificatore",
      message: "",
      permissions: [
        "general",
      ]
    },

    {
      code: "scanner",
      title: "Scanner",
      message: "",
      permissions: [
        "general",
      ]
    },

    {
      code: "trackme",
      title: "TrackMe",
      message: "",
      permissions: [
        "general",
        "registry.view_subject",
        "registry.change_subject",
        "distribution.view_deliverystatus",
        "distribution.change_deliverystatus",
      ]
    },

    {
      code: "carrier",
      title: "Vettore",
      message: "",
      permissions: [
        "general",
        "base.view_carrierdelivery",
        "carriers.view_carriernote",
        "carriers.view_carriercashbox",
        "distribution.view_reasonstatus",
        "distribution.change_note",
      ]
    },

    {
      code: "permission_1",
      title: "Permessi Check 1",
      message: "",
      permissions: [
        "general",
        "base.autocomplete_general_view",
        "base.change_workpermit_c1",
        "employees.view_workpermit",
        "employees.change_workpermit",
        "employees.view_workpermitrule",
        "employees.change_workpermitrule",
      ]
    },

    {
      code: "permission_2",
      title: "Permessi Check 2",
      message: "",
      permissions: [
        "general",
        "base.autocomplete_general_view",
        "base.change_workpermit_c2",
        "employees.view_workpermit",
        "employees.change_workpermit",
        "employees.view_workpermitrule",
        "employees.change_workpermitrule",
      ]
    },

    {
      code: "permission_3",
      title: "Permessi Check 3",
      message: "",
      permissions: [
        "general",
        "base.autocomplete_general_view",
        "base.change_workpermit_c3",
        "employees.view_workpermit",
        "employees.change_workpermit",
        "employees.view_workpermitrule",
        "employees.change_workpermitrule",
      ]
    },

    {
      code: "employee_hr",
      title: "Gestione Risorse Umane",
      message: "",
      permissions: [
        "general",
        "base.autocomplete_general_view",
        "employees.change_badge",
        "employees.view_badge",
        "employees.change_employee",
        "employees.view_employee",
        "employees.view_presence",
        "employees.change_presence",
        "employees.view_presenceqr",
        "employees.change_presenceqr",
        "employees.view_presencezone",
        "employees.change_presencezone"
      ]
    },

  ];

  const grouppedPermisison = groupPermissionForMain();

  return (

    <Grid
      container
      alignItems="center"
      justifyContent="flex-start"
      spacing={2}
    >
      <Grid item xs={6}>
        <Typography variant="h3" align="center">Permessi</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h3" align="center">Gruppi</Typography>
      </Grid>

      <Grid item xs={6}>
        <TreeView
          className={classes.root}
          defaultExpanded={['1']}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<CloseSquare />}
        >
          <StyledTreeItem nodeId="1" label="Permessi">
            {
              grouppedPermisison ? grouppedPermisison.map((group: any) => {
                return (
                  <StyledTreeItem nodeId={group.group} label={group.group}>
                    {
                      group.permissions.map((permission: any) => {
                        return (
                          <StyledTreeItem nodeId={permission} label={permission.title} title={permission.message} />
                        );
                      })
                    }
                  </StyledTreeItem>
                );
              }
              ) : null
            }
          </StyledTreeItem>
        </TreeView>
      </Grid>

      <Grid item xs={6}>
        <TreeView
          className={classes.root}
          defaultExpanded={['groups']}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<CloseSquare />}
        >
          <StyledTreeItem nodeId={"groups"} label={"Gruppi"}>
            {
              groups_info.map((group) => {
                let groupGrouppedPermisisons = groupPermissionForMainSelected(group.permissions as [string]);
                return (
                  <StyledTreeItem nodeId={group.code} label={group.title}>
                    {
                      groupGrouppedPermisisons ? groupGrouppedPermisisons.map((group: any) => {
                        return (
                          <StyledTreeItem nodeId={group.group} label={group.group}>
                            {
                              group.permissions.map((permission: any) => {
                                return (
                                  <StyledTreeItem nodeId={permission} label={permission.title} title={permission.message} />
                                );
                              })
                            }
                          </StyledTreeItem>
                        );
                      }
                      ) : null
                    }

                  </StyledTreeItem>
                );
              })
            }
          </StyledTreeItem>

        </TreeView>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h3" align="center">Gruppi utenti</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h3" align="center">Utenti gruppi</Typography>
      </Grid>
      <Grid item xs={6}>
        <TreeView
          className={classes.root}
          defaultExpanded={['groups']}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<CloseSquare />}
        >
          <StyledTreeItem nodeId={"users_groups"} label={"Gruppi utenti"}>
            {
              usersGroupped.map((group: any) => {
                return (
                  <StyledTreeItem nodeId={group.group} label={getTitoleFromGroupCode(group.group)}>
                    {
                      group.users ? group.users.map((user: any) => {
                        return (
                          <StyledTreeItem nodeId={user} label={user} />
                        );
                      }
                      ) : null
                    }

                  </StyledTreeItem>
                );
              })
            }
          </StyledTreeItem>

        </TreeView>
      </Grid>
      <Grid item xs={6}>
        <TreeView
          className={classes.root}
          defaultExpanded={['groups']}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<CloseSquare />}
        >
          <StyledTreeItem nodeId={"groups_user"} label={"Utenti gruppi"}>
            {
              groupsUser.map((user: any) => {
                return (
                  <StyledTreeItem nodeId={user.user} label={user.user}>
                    {
                      user.groups ? user.groups.map((group: any) => {
                        return (
                          <StyledTreeItem nodeId={group} label={getTitoleFromGroupCode(group)} />
                        );
                      }
                      ) : null
                    }

                  </StyledTreeItem>
                );
              })
            }
          </StyledTreeItem>

        </TreeView>
      </Grid>
    </Grid>


  );
};


/*
[
  "employees.view_employee",
  "distribution.delete_route",
  "accounting.add_invoice",
  "route_management.delete_historicalroute",
  "accounting.add_chartaccounts",
  "carriers.add_carriercachetpayment",
  "distribution.view_item",
  "registry.view_historicalreferrer",
  "distribution.view_historicalvehicle",
  "distribution.add_historicalitem",
  "cash_on_delivery.change_remittance",
  "route_management.view_tag",
  "distribution.delete_deliveryattachment",
  "distribution.add_remittance",
  "accounting.change_termsdescription",
  "accounting.view_historicalinvoice",
  "distribution.add_historicalvehicle",
  "accounting.change_charge",
  "distribution.view_historicalcategory",
  "distribution.change_stock",
  "registry.add_user",
  "distribution.view_recurrencedelivery",
  "customers.delete_customersubject",
  "accounting.change_historicalaccountcompany",
  "distribution.add_cachet",
  "accounting.add_historicalchartaccounts",
  "cash_on_delivery.view_cachetpayment",
  "auth.delete_user",
  "distribution.delete_reasonstatus",
  "distribution.delete_historicaldeliverystatus",
  "accounting.add_historicalinvoiceline",
  "accounting.view_historicaltermsdescription",
  "accounting.add_bank",
  "registry.add_historicalreferrer",
  "distribution.view_historicaldeliveryitem",
  "distribution.add_historicaldeliveryattachment",
  "auth.add_group",
  "registry.change_historicaluser",
  "accounting.delete_historicalchartaccountstag",
  "distribution.add_price",
  "distribution.add_deliveryattachment",
  "accounting.view_historicalinvoicepaymentstatus",
  "registry.view_site",
  "accounting.delete_bank",
  "distribution.add_recurrencedelivery",
  "distribution.add_historicalcachet",
  "cash_on_delivery.display_cachet_cashed",
  "auth.view_user",
  "file_import.delete_missingsubject",
  "customers.delete_historicalcustomerorderitem",
  "employees.change_historicalemployee",
  "email_handler.change_email",
  "printer.add_printer",
  "distribution.delete_historicalremittance",
  "accounting.add_historicalaccountcompany",
  "carriers.delete_carriercachetpayment",
  "distribution.view_tariff",
  "route_management.change_historicalroute",
  "distribution.delete_historicaldeliveryzone",
  "customers.view_customeruserclient",
  "distribution.view_stock",
  "cash_on_delivery.delete_historicalcachetbox",
  "registry.delete_historicalspecificationnote",
  "notification.delete_notificationchatid",
  "admin.delete_logentry",
  "token_blacklist.change_blacklistedtoken",
  "registry.delete_referrer",
  "file_import.view_missingsubject",
  "base.view_deliverytracking",
  "distribution.view_historicalroute",
  "distribution.add_vehicle",
  "customers.change_customersubject",
  "route_management.add_historicalroute",
  "distribution.view_vehicle",
  "accounting.change_chartaccounts",
  "registry.delete_site",
  "accounting.delete_historicalaccountcompany",
  "accounting.delete_historicalcharge",
  "registry.view_specificationnote",
  "distribution.delete_tariff",
  "accounting.view_historicalchartaccountstag",
  "route_management.delete_tag",
  "registry.add_historicalspecificationnote",
  "customers.delete_historicalcustomerimportfile",
  "registry.view_historicaluser",
  "accounting.view_chartaccountstag",
  "distribution.change_recurrencedelivery",
  "distribution.add_historicaldelivery",
  "accounting.add_invoicechartaccounts",
  "base.delete_siteconfiguration",
  "distribution.add_stock",
  "route_management.delete_historicaltag",
  "token_blacklist.delete_blacklistedtoken",
  "file_import.delete_defaultimportitem",
  "customers.view_customerorder",
  "customers.change_historicalcustomerimportfile",
  "distribution.change_vehicle",
  "token_blacklist.view_blacklistedtoken",
  "registry.add_referrer",
  "accounting.add_historicalcharge",
  "customers.change_customerorderitem",
  "contenttypes.delete_contenttype",
  "distribution.delete_splitsegment",
  "distribution.add_subtariff",
  "accounting.add_historicaltermsdescription",
  "notification.change_notificationchatid",
  "file_import.delete_importedfile",
  "cash_on_delivery.change_cachetbox",
  "customers.view_customersubject",
  "registry.view_historicalspecificationnote",
  "accounting.add_historicalbank",
  "registry.delete_historicalreferrer",
  "registry.change_specificationnote",
  "accounting.view_termsdescription",
  "registry.delete_historicalsite",
  "customers.add_customeruserclient",
  "accounting.change_historicalinvoicepaymentstatus",
  "distribution.delete_historicaldriver",
  "employees.delete_historicalemployee",
  "distribution.add_scannerconfig",
  "distribution.view_deliveryzone",
  "file_import.view_defaultimportitem",
  "registry.view_subject",
  "cash_on_delivery.add_remittance",
  "distribution.view_historicalnote",
  "base.autocomplete_general_view",
  "distribution.delete_category",
  "accounting.view_historicalbank",
  "carriers.view_carriercachetpayment",
  "registry.view_referrer",
  "cash_on_delivery.change_cachetpayment",
  "file_import.add_defaultimportitem",
  "route_management.delete_route",
  "file_import.change_importfunctionclient",
  "registry.change_historicalreferrer",
  "accounting.delete_charge",
  "distribution.view_reasonstatus",
  "ocr_config.delete_scannerconfig",
  "distribution.delete_historicaltariff",
  "distribution.view_cachet",
  "distribution.add_segment",
  "cash_on_delivery.change_historicalcachet",
  "accounting.delete_historicaltermsdescription",
  "distribution.view_subtariff",
  "distribution.change_package",
  "distribution.delete_deliveryitem",
  "distribution.delete_price",
  "employees.delete_employee",
  "accounting.view_chartaccounts",
  "comuni_italiani.delete_regione",
  "printer.delete_printer",
  "comuni_italiani.view_provincia",
  "accounting.delete_historicalinvoicepaymentstatus",
  "accounting.change_termspayment",
  "file_import.add_importfunctionclient",
  "registry.change_referrer",
  "fleet_management.view_historicalvehicle",
  "distribution.change_deliveryitem",
  "cash_on_delivery.add_cachet",
  "accounting.add_historicaltermspayment",
  "customers.change_historicalcustomerorderitem",
  "registry.add_historicalsubject",
  "accounting.delete_historicalinvoiceline",
  "accounting.view_historicalbillaccount",
  "printer.view_printer",
  "accounting.change_historicalinvoice",
  "printer.change_printer",
  "accounting.change_historicaltermsdescription",
  "cash_on_delivery.delete_historicalcachet",
  "cash_on_delivery.display_cachet_to_cash_in",
  "accounting.delete_invoice",
  "route_management.add_tag",
  "distribution.view_package",
  "distribution.add_note",
  "distribution.add_historicaldeliveryitem",
  "distribution.delete_deliveryzone",
  "cash_on_delivery.add_cachetbox",
  "route_management.view_historicalroute",
  "carriers.change_carriercachetpayment",
  "distribution.change_splitsegment",
  "customers.change_historicalcustomerorder",
  "route_management.add_route",
  "ocr_config.view_scannerconfig",
  "accounting.delete_chartaccountstag",
  "customers.delete_customerimportfile",
  "distribution.view_deliveryitem",
  "distribution.change_historicalremittance",
  "comuni_italiani.change_regione",
  "admin.add_logentry",
  "file_import.add_importedfile",
  "cash_on_delivery.add_historicalcachetbox",
  "distribution.delete_package",
  "accounting.delete_termspayment",
  "registry.add_specificationnote",
  "distribution.add_historicalprice",
  "token_blacklist.delete_outstandingtoken",
  "route_management.view_route",
  "customers.add_historicalcustomersubject",
  "accounting.view_historicalinvoiceline",
  "distribution.change_historicalprice",
  "customers.add_historicalcustomerorder",
  "file_import.view_importedfile",
  "customers.change_customeruserclient",
  "registry.view_historicalsite",
  "accounting.view_billaccount",
  "customers.view_historicalcustomerorder",
  "accounting.delete_invoiceline",
  "file_import.change_missingsubject",
  "distribution.delete_subtariff",
  "cash_on_delivery.delete_cachetbox",
  "accounting.change_historicalchartaccounts",
  "contenttypes.view_contenttype",
  "distribution.change_historicalitem",
  "registry.view_user",
  "accounting.add_termsdescription",
  "auth.view_group",
  "customers.add_customersubject",
  "carriers.view_carriercashbox",
  "contenttypes.change_contenttype",
  "distribution.add_deliverystatus",
  "file_import.view_historicalimportedfile",
  "route_management.view_historicaltag",
  "distribution.view_historicaldeliverystatus",
  "customers.add_customerimportfile",
  "file_import.change_historicalimportedfile",
  "file_import.change_defaultimportitem",
  "comuni_italiani.change_cittametropolitana",
  "distribution.delete_historicalvehicle",
  "distribution.view_historicaldeliveryattachment",
  "distribution.delete_historicalroute",
  "distribution.view_historicaldelivery",
  "distribution.delete_delivery",
  "distribution.change_historicalvehicle",
  "fleet_management.add_vehicle",
  "base.autocomplete_customer_view",
  "registry.delete_historicalsubject",
  "distribution.change_deliveryzone",
  "accounting.change_invoiceline",
  "accounting.delete_historicalbillaccount",
  "distribution.view_segment",
  "auth.change_user",
  "cash_on_delivery.add_historicalcachet",
  "distribution.view_route",
  "distribution.view_deliverynoterow",
  "distribution.add_historicalcategory",
  "customers.change_historicalcustomersubject",
  "employees.add_historicalemployee",
  "distribution.add_historicalnote",
  "base.view_carrierdelivery",
  "fleet_management.delete_historicalvehicle",
  "distribution.change_historicaldeliveryattachment",
  "registry.delete_subject",
  "carriers.change_carrierusersubject",
  "accounting.add_historicalchartaccountstag",
  "accounting.change_historicalinvoicechartaccounts",
  "distribution.change_route",
  "cash_on_delivery.add_historicalremittance",
  "distribution.delete_historicalnote",
  "accounting.add_charge",
  "distribution.add_historicaldeliveryzone",
  "customers.view_historicalcustomersubject",
  "notification.add_notificationchatid",
  "sessions.change_session",
  "distribution.add_package",
  "distribution.delete_historicalcachet",
  "registry.view_historicalsubject",
  "distribution.view_historicaltariff",
  "comuni_italiani.view_cittametropolitana",
  "distribution.change_remittance",
  "registry.change_historicalsite",
  "accounting.add_chartaccountstag",
  "customers.view_historicalcustomerorderitem",
  "admin.view_logentry",
  "comuni_italiani.add_comune",
  "distribution.change_historicaldeliveryzone",
  "distribution.add_historicalpackage",
  "distribution.change_tariff",
  "accounting.delete_invoicepaymentstatus",
  "distribution.change_historicalpackage",
  "base.change_carrierdelivery",
  "cash_on_delivery.view_historicalcachetbox",
  "customers.change_customerorder",
  "distribution.add_route",
  "comuni_italiani.view_regione",
  "registry.change_historicalsubject",
  "distribution.add_historicaldeliverynoterow",
  "distribution.view_historicalitem",
  "accounting.delete_accountcompany",
  "accounting.view_historicalchartaccounts",
  "comuni_italiani.delete_comune",
  "customers.add_customerorderitem",
  "distribution.change_historicalstock",
  "carriers.add_carrierusersubject",
  "carriers.delete_carrierusersubject",
  "distribution.add_deliverynoterow",
  "distribution.view_note",
  "distribution.view_deliverystatus",
  "distribution.delete_historicaldeliveryattachment",
  "distribution.view_remittance",
  "customers.delete_customeruserclient",
  "distribution.change_scannerconfig",
  "distribution.change_historicaldelivery",
  "distribution.add_deliveryitem",
  "distribution.change_cachet",
  "accounting.add_invoicepaymentstatus",
  "sessions.delete_session",
  "customers.change_customerimportfile",
  "distribution.add_historicalstock",
  "carriers.change_carriercashbox",
  "accounting.view_invoice",
  "distribution.delete_historicalstock",
  "token_blacklist.add_outstandingtoken",
  "base.change_siteconfiguration",
  "cash_on_delivery.view_cachetbox",
  "distribution.add_historicalsegment",
  "contenttypes.add_contenttype",
  "distribution.delete_historicalcategory",
  "auth.add_permission",
  "employees.change_department",
  "comuni_italiani.view_comune",
  "accounting.delete_historicalinvoicechartaccounts",
  "cash_on_delivery.delete_remittance",
  "distribution.change_driver",
  "distribution.delete_driver",
  "distribution.delete_remittance",
  "cash_on_delivery.add_cachetpayment",
  "auth.change_permission",
  "base.add_siteconfiguration",
  "notification.view_notificationchatid",
  "distribution.change_deliverynoterow",
  "distribution.delete_stock",
  "accounting.change_invoicepaymentstatus",
  "distribution.change_item",
  "distribution.view_delivery",
  "cash_on_delivery.delete_historicalremittance",
  "distribution.add_driver",
  "file_import.delete_importfunctionclient",
  "distribution.change_historicalroute",
  "fleet_management.change_historicalvehicle",
  "employees.view_department",
  "customers.delete_historicalcustomerorder",
  "auth.change_group",
  "cash_on_delivery.delete_cachet",
  "comuni_italiani.add_provincia",
  "accounting.change_invoice",
  "customers.view_historicalcustomerimportfile",
  "carriers.view_carrierusersubject",
  "auth.add_user",
  "accounting.view_accountcompany",
  "file_import.change_importedfile",
  "distribution.change_price",
  "accounting.delete_invoicechartaccounts",
  "customers.view_customerorderitem",
  "accounting.delete_historicalbank",
  "employees.change_employee",
  "registry.change_historicalspecificationnote",
  "distribution.view_historicalstock",
  "fleet_management.change_vehicle",
  "distribution.delete_deliverynoterow",
  "distribution.change_historicalsegment",
  "distribution.delete_historicalsegment",
  "registry.add_historicalsite",
  "accounting.change_historicalbank",
  "registry.change_site",
  "accounting.delete_billaccount",
  "customers.delete_customerorderitem",
  "cash_on_delivery.view_remittance",
  "comuni_italiani.add_regione",
  "cash_on_delivery.view_cachet",
  "distribution.add_tariff",
  "accounting.add_historicalinvoicechartaccounts",
  "customers.delete_customerorder",
  "route_management.change_route",
  "auth.view_permission",
  "distribution.view_historicaldeliveryzone",
  "accounting.view_termspayment",
  "accounting.add_billaccount",
  "distribution.delete_pricerange",
  "cash_on_delivery.change_cachet",
  "accounting.view_historicalinvoicechartaccounts",
  "accounting.change_accountcompany",
  "route_management.change_tag",
  "accounting.change_bank",
  "file_import.delete_historicalimportedfile",
  "customers.add_customerorder",
  "distribution.add_historicaldriver",
  "distribution.delete_historicaldeliveryitem",
  "accounting.change_historicalinvoiceline",
  "distribution.view_scannerconfig",
  "registry.change_user",
  "distribution.change_reasonstatus",
  "distribution.change_subtariff",
  "distribution.view_historicalprice",
  "accounting.view_invoicechartaccounts",
  "accounting.view_historicaltermspayment",
  "distribution.delete_segment",
  "distribution.delete_vehicle",
  "distribution.view_price",
  "distribution.view_deliveryattachment",
  "distribution.view_splitsegment",
  "accounting.add_historicalbillaccount",
  "sessions.add_session",
  "distribution.change_category",
  "distribution.view_historicaldriver",
  "accounting.change_historicalbillaccount",
  "distribution.add_pricerange",
  "distribution.view_historicalcachet",
  "accounting.add_historicalinvoicepaymentstatus",
  "distribution.change_deliveryattachment",
  "distribution.add_category",
  "distribution.change_historicaldeliverystatus",
  "distribution.delete_note",
  "registry.delete_historicaluser",
  "route_management.add_historicaltag",
  "comuni_italiani.change_comune",
  "accounting.delete_termsdescription",
  "distribution.delete_historicalprice",
  "registry.delete_user",
  "distribution.change_historicaldriver",
  "distribution.change_segment",
  "accounting.view_historicalcharge",
  "distribution.delete_historicalpackage",
  "employees.add_department",
  "distribution.view_historicalpackage",
  "accounting.add_historicalinvoice",
  "distribution.view_historicalremittance",
  "distribution.change_historicalcategory",
  "distribution.view_historicalsegment",
  "distribution.view_historicaldeliverynoterow",
  "distribution.delete_scannerconfig",
  "accounting.add_termspayment",
  "accounting.delete_historicaltermspayment",
  "ocr_config.change_scannerconfig",
  "distribution.view_pricerange",
  "carriers.delete_carriercashbox",
  "distribution.change_note",
  "registry.delete_specificationnote",
  "accounting.view_invoicepaymentstatus",
  "comuni_italiani.delete_cittametropolitana",
  "registry.change_subject",
  "distribution.change_historicalnote",
  "employees.view_historicalemployee",
  "accounting.add_invoiceline",
  "distribution.change_historicaltariff",
  "cash_on_delivery.delete_cachetpayment",
  "registry.add_historicaluser",
  "ocr_config.add_scannerconfig",
  "distribution.add_reasonstatus",
  "cash_on_delivery.change_historicalcachetbox",
  "sessions.view_session",
  "file_import.add_missingsubject",
  "distribution.add_historicaltariff",
  "customers.view_customerimportfile",
  "distribution.view_category",
  "accounting.change_billaccount",
  "customers.add_historicalcustomerimportfile",
  "token_blacklist.view_outstandingtoken",
  "fleet_management.view_vehicle",
  "accounting.change_chartaccountstag",
  "file_import.add_historicalimportedfile",
  "distribution.delete_recurrencedelivery",
  "customers.delete_historicalcustomersubject",
  "distribution.delete_deliverystatus",
  "accounting.change_historicalcharge",
  "accounting.delete_historicalinvoice",
  "accounting.change_invoicechartaccounts",
  "distribution.add_historicaldeliverystatus",
  "distribution.delete_item",
  "distribution.add_deliveryzone",
  "distribution.add_item",
  "fleet_management.delete_vehicle",
  "accounting.view_bank",
  "accounting.add_accountcompany",
  "auth.delete_permission",
  "cash_on_delivery.change_historicalremittance",
  "distribution.change_historicaldeliverynoterow",
  "distribution.delete_historicalitem",
  "token_blacklist.add_blacklistedtoken",
  "distribution.view_driver",
  "accounting.delete_historicalchartaccounts",
  "registry.add_subject",
  "cash_on_delivery.view_historicalremittance",
  "distribution.add_splitsegment",
  "fleet_management.add_historicalvehicle",
  "base.view_siteconfiguration",
  "carriers.add_carriercashbox",
  "admin.change_logentry",
  "customers.add_historicalcustomerorderitem",
  "distribution.change_deliverystatus",
  "file_import.view_importfunctionclient",
  "accounting.view_historicalaccountcompany",
  "accounting.change_historicalchartaccountstag",
  "employees.delete_department",
  "cash_on_delivery.view_historicalcachet",
  "token_blacklist.change_outstandingtoken",
  "comuni_italiani.add_cittametropolitana",
  "accounting.view_charge",
  "distribution.change_pricerange",
  "distribution.add_historicalroute",
  "employees.add_employee",
  "accounting.delete_chartaccounts",
  "distribution.delete_historicaldelivery",
  "email_handler.view_email",
  "distribution.change_delivery",
  "registry.add_site",
  "comuni_italiani.delete_provincia",
  "route_management.change_historicaltag",
  "distribution.add_delivery",
  "distribution.change_historicaldeliveryitem",
  "distribution.add_historicalremittance",
  "distribution.delete_historicaldeliverynoterow",
  "distribution.delete_cachet",
  "distribution.change_historicalcachet",
  "comuni_italiani.change_provincia",
  "accounting.view_invoiceline",
  "email_handler.delete_email",
  "email_handler.add_email",
  "accounting.change_historicaltermspayment",
  "auth.delete_group",
  "base.change_workpermit_c1",
  "base.change_workpermit_c2",
  "base.change_workpermit_c3",
 ]
*/