import { Card } from "@material-ui/core";
import { CreateProps } from "react-admin";
import { CustomCreate } from "../../components/CustomCreate";
import { WorkPermitForm } from "./WorkPermitForm";
import { WorkPermitRuleForm } from "./WorkPermitRuleForm";

export const WorkPermitRuleCreate = (props: CreateProps) => {
  return (
    <CustomCreate {...props} component="div" >
      <WorkPermitRuleForm />
    </CustomCreate>
  );
};
