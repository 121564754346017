import {
  Datagrid,
  TextField,
  ListProps,
  DateField,
  TextInput,
  BooleanField,
  DateInput,
} from "react-admin";
import { CustomFileField } from "../components/CustomFIleField";
import { CustomList } from "../components/CustomList";
import { SubjectField } from "../components/SubjectField";
import { GoToDeliveriesButton } from "../components/GoToDeliveriesButton";
import { useOptionsResource } from "../shared/hooks/useOptionsResource";
import { initField, getDefaultListProps } from "../shared/utils";
import { ReferenceInputSubject } from "../components/reference-inputs/ReferenceInputSubject";

export const ImportList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");
  const filters = options
    ? [
      <TextInput {...initField("id", options)} />,
      <ReferenceInputSubject
        {...initField("client", options)}
        filter={{ type: "client" }}
      />,
      <DateInput {...initField("created_date", options)} />,
    ]
    : [];
  return options ? (
    <CustomList
      filters={filters}
      {...getDefaultListProps(props)}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <TextField {...initField("id", options)} />
        <SubjectField {...initField("client", options)} />
        <CustomFileField
          {...initField("file", options)}
          title="name"
          src="url"
        />
        <BooleanField {...initField("correct", options)} />
        <DateField {...initField("vehicle_arrival", options)} />
        <DateField {...initField("created_date", options)} showTime />
        <GoToDeliveriesButton filterAttribute={"import_file"} />
      </Datagrid>
    </CustomList>
  ) : null;
};
