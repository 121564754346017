import {
  useTheme
} from "@material-ui/core";
import { useState } from "react";
import {
  Record,
} from "react-admin";
import { CustomList, CustomListProps } from "../../../components/CustomList";
import {
  dateFormatter,
  subtractMonth,
  getDefaultListProps,
} from "../../../shared/utils";
import { SupplierGroupedChargeDatagrid } from "./SupplierGroupedChargeDatagrid";
import { ChargeAside } from "../ChargeAside";

const beforeDate = dateFormatter(new Date());
const afterDate = dateFormatter(subtractMonth(new Date(), 1));



export const PurchaseChargesList = (
  props: Omit<CustomListProps, "children"> & { Actions: React.ElementType }
) => {
  const [activeCharge, setActiveCharge] = useState<Record>();
  const theme = useTheme();

  const rowStyle = (record: Record, index: number) => ({
    backgroundColor: record.charges.find(
      (charge: Record) => charge.id === activeCharge?.id
    )
      ? theme.palette.primary.light
      : "white",
  });

  const { Actions, ...rest } = props;
  return (
    <CustomList
      {...getDefaultListProps(rest)}
      aside={
        <ChargeAside
          closeAside={() => setActiveCharge(undefined)}
          activeCharge={activeCharge}
        />
      }
      sort={{ field: "charge_date", order: "ASC" }}
      perPage={0}
      pagination={false}
      filterDefaultValues={{
        charge_date_after: afterDate,
        charge_date_before: beforeDate,
        supplier: "",
      }}
    >
      <SupplierGroupedChargeDatagrid
        Actions={Actions}
        activeCharge={activeCharge}
        rowStyle={rowStyle}
        setActiveCharge={setActiveCharge}
      />
    </CustomList>
  );
};
