import {
    useTheme,
    Box,
    IconButton,
    Typography,
    TableContainer,
    makeStyles,
    Slider,
} from "@material-ui/core";
import {
    Datagrid,
    NumberField,
    Record,
    RecordContextProvider,
    TextField,
} from "react-admin";
import { usePanzoom } from "../../shared/hooks/usePanzoom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSize } from "../../shared/hooks/useSize";
import { getNoteImage, saveNoteToFile } from "../../Distribution/Note/utils";
import { DeliveryField } from "../../components/DeliveryField";
import { SubjectField } from "../../components/SubjectField";
import NavigateNext from "@material-ui/icons/SkipNext";
import NavigatePrev from "@material-ui/icons/SkipPrevious";
import GetAppIcon from "@material-ui/icons/GetApp";
import Close from "@material-ui/icons/Close";
import RotateRightIcon from '@material-ui/icons/RotateRight';


interface ChargeAsideProps {
    activeCharge: Record | undefined;
    closeAside: () => void;
}
interface NoteGalleryProps {
    activeNoteImage: string;
    fileName: string;
    rotation: number;
}
const NoteGallery = ({ activeNoteImage, fileName, rotation }: NoteGalleryProps) => {
    const handlePanzoom = usePanzoom();

    const theme = useTheme();

    return (
        <>
            <div ref={handlePanzoom} style={{ height: "100%" }}>
                <img
                    alt={fileName}
                    style={{ width: "auto", height: "100%", transform: `rotate(${rotation}deg)` }}
                    src={activeNoteImage}
                />
            </div>

        </>
    );
};
export const ChargeAside = (props: ChargeAsideProps) => {
    const { activeCharge, closeAside } = props;
    const ref = useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const size = useSize(document.getElementById("layout-main-content"));
    const sizeDeliveryDetails = useSize(ref.current);
    const heightNoteBox =
        (size ? size.height : 0) -
        (sizeDeliveryDetails ? sizeDeliveryDetails.height : 0);

    const [indexActiveNote, setIndexActiveNote] = useState<number>(0);
    const [activeNoteImage, setActiveNoteImage] = useState<string>();
    const goNext = () => {
        const nextIndex = indexActiveNote + 1;
        if (
            activeCharge &&
            activeCharge.delivery_notes &&
            nextIndex < activeCharge.delivery_notes.length
        )
            setIndexActiveNote(nextIndex);
    };
    const goPrev = () => {
        const prevIndex = indexActiveNote - 1;
        if (prevIndex >= 0) setIndexActiveNote(prevIndex);
    };
    const canGoPrev = useMemo(() => indexActiveNote - 1 >= 0, [indexActiveNote]);
    const canGoNext = useMemo(
        () =>
            activeCharge &&
            activeCharge.delivery_notes &&
            indexActiveNote + 1 < activeCharge.delivery_notes.length,
        [activeCharge, indexActiveNote]
    );

    useEffect(() => {
        if (
            activeCharge &&
            activeCharge.delivery_notes &&
            activeCharge.delivery_notes.length > 0
        )
            setIndexActiveNote(0)
    },
        [activeCharge])

    const getActiveNoteImage = useCallback(async () => {
        if (
            activeCharge &&
            activeCharge.delivery_notes &&
            activeCharge.delivery_notes.length > 0 &&
            activeCharge.delivery_notes.length > indexActiveNote
        ) {
            const image = await getNoteImage(
                activeCharge.delivery_notes[indexActiveNote].id
            );
            return image;
        }
    }, [activeCharge, indexActiveNote]);

    useEffect(() => {
        (async function fetchImage() {
            const image = await getActiveNoteImage();
            setActiveNoteImage(image);
        })();
    }, [getActiveNoteImage]);

    const [rotation, setRotation] = useState(0);

    const [dimension, setDimension] = useState(700);
    const [showDimension, setShowDimension] = useState(700);

    const handleChangeView = (event: any, newValue: any) => {
        setShowDimension(newValue);
    };

    const handleChange = (event: any, newValue: any) => {
        setShowDimension(newValue);
        setDimension(newValue);
    };

    const [height, setHeight] = useState(150);
    const [showHeight, setShowHeight] = useState(150);

    const handleChangeView2 = (event: any, newValue: any) => {
        setShowHeight(newValue);
    };

    const handleChange2 = (event: any, newValue: any) => {
        setShowHeight(newValue);
        setHeight(newValue);
    };

    return activeCharge ? (
        <RecordContextProvider value={activeCharge}>
            <Box height={size?.height} position="sticky" top="0" bgcolor={"white"}>
                <div ref={ref}>
                    <TableContainer>
                        <Datagrid
                            data={{ [activeCharge.id]: activeCharge }}
                            ids={[activeCharge.id]}
                        >
                            <DeliveryField source="id" deliverySource="id" label="ID" noTitle={true} />
                            <SubjectField source="receiver" label="Destinatario" />
                            <SubjectField source="sender" label="Mittente" />
                            <TextField source="document_number" label="Num. documento" />

                            <TextField source="document_date" label="Data documento" />

                            <NumberField source="packages_number" label="N° colli" />

                            <NumberField source="weight" label="Peso" />
                        </Datagrid>
                    </TableContainer>
                </div>

                <Box height={heightNoteBox} minWidth={dimension + "px"}>
                    <Box
                        height={height + "%"}
                        overflow="hidden"
                        position="relative"
                        bgcolor="white"
                        boxSizing="border-box"
                    >
                        {activeCharge.delivery_notes &&
                            activeCharge.delivery_notes.length > 0 &&
                            activeNoteImage &&
                            activeCharge.delivery_notes[indexActiveNote] &&
                            activeCharge.delivery_notes[indexActiveNote].file ? (
                            <NoteGallery
                                activeNoteImage={activeNoteImage}
                                fileName={activeCharge.delivery_notes[indexActiveNote].file}
                                rotation={rotation}
                            />
                        ) : (
                            <>
                                <br />
                                <br />
                                <br />
                                <Typography variant={"h2"} color={"error"}>
                                    ATTENZIONE <br /> BOLLA MANCANTE, <br />
                                    PRIMA DI PROCEDERE <br />RECUPERARLA!
                                </Typography>
                            </>
                        )}
                        <Box
                            position={"absolute"}
                            top="0"
                            display={"flex"}
                            justifyContent="space-between"
                            width={"100%"}
                            padding={1}
                            boxSizing="border-box"
                            bgcolor={"transparent"}
                            alignItems="center"
                        >
                            <Box display={"flex"} alignItems="center"
                                bgcolor={"white"}>
                                <IconButton disabled={!canGoPrev} onClick={goPrev}>
                                    <NavigatePrev />
                                </IconButton>
                                <Box mx={2}>
                                    <Typography>
                                        {indexActiveNote + 1}/{activeCharge.delivery_notes.length}
                                    </Typography>
                                </Box>
                                <IconButton disabled={!canGoNext} onClick={goNext}>
                                    <NavigateNext />
                                </IconButton>
                            </Box>

                            <Box display={"flex"} alignItems="center" sx={{ width: 100 }}>

                                <Slider style={{ top: "10px" }}
                                    aria-label="Dimensione riquadro"
                                    valueLabelDisplay="auto"
                                    min={300} max={1400} value={showDimension}
                                    onChange={handleChangeView}
                                    onChangeCommitted={handleChange} />
                                <Typography>L</Typography>
                            </Box>

                            <Box display={"flex"} alignItems="center" sx={{ width: 100 }}>
                                <Slider style={{ top: "10px" }}
                                    aria-label="Altezza riquadro"
                                    valueLabelDisplay="auto"
                                    min={10} max={200} value={showHeight}
                                    onChange={handleChangeView2}
                                    onChangeCommitted={handleChange2} />
                                <Typography>H</Typography>
                            </Box>


                            <Box display={"flex"} gridColumnGap="2px">
                                <IconButton
                                    disabled={!activeNoteImage}
                                    style={{
                                        background: theme.palette.primary.main,
                                        color: "white",
                                    }}
                                    onClick={() => {
                                        if (activeNoteImage) {
                                            saveNoteToFile(
                                                activeCharge.delivery_notes[indexActiveNote].id
                                            );
                                        }
                                    }}
                                >
                                    <GetAppIcon />
                                </IconButton>
                                <IconButton
                                    style={{
                                        background: theme.palette.primary.main,
                                        color: "white",
                                    }}
                                    onClick={closeAside}
                                >
                                    <Close />
                                </IconButton>
                                <IconButton
                                    style={{ background: theme.palette.primary.main, color: "white" }}
                                    onClick={() => {
                                        setRotation(rotation + 90);
                                    }}
                                >
                                    <RotateRightIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </RecordContextProvider>
    ) : null;
};